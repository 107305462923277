<template>
  <v-card v-if="loadingPage()" class="mt-2">
    <v-alert v-if="visibile" :type="typeAlert">
      <span> {{ textAlert }} </span>
    </v-alert>
    <DialogConfermaMonitoraggio
      v-if="dialogMonitoraggio"
      :show-dialog="true"
      class="dialog-conferma-monitoraggio"
      @callback="confermaMonitoraggio()"
      title="Conferma dati Monitoraggio"
      :text-conferma="getTextDialog()"
      :color-toolbar="'error'"
    />
    <v-snackbar :color="snackbar.color" v-model="snackbar.value">
      <span> {{ snackbar.text }}</span>
    </v-snackbar>
  </v-card>
</template>
<style scoped>
.dialog-conferma-monitoraggio {
  text-align: center;
  max-width: 600px;
}
</style>
<script>
import IstitutoRepository from "@/api/istituto/IstitutoRepository";
import MonitoraggiRepository from "@/api/istituto/MonitoraggiRepository";
import DialogConfermaMonitoraggio from "@/components/Monitoraggi/DialogConfermaMonitoraggio.vue";
import { mapGetters, mapState } from "vuex";

export default {
  name: "AlertMonitoraggi",
  components: { DialogConfermaMonitoraggio },
  created() {
    this.initialize();
  },
  computed: {
    ...mapState(["idStrutture", "anno"]),
    ...mapGetters(["isRoleLegale", "isRoleConsulenza"]),
    loading: () => {
      return;
    },
  },
  watch: {
    anno() {
      this.initialize();
    },
    idStrutture() {
      this.initialize();
    },
  },
  data: () => ({
    finestraMonitoraggi: null,
    ultimoMonitoraggio: null,
    typeAlert: "info",
    textAlert: "",
    visibile: false,
    chiamataApiMonitoraggi: false,
    chiamataApiFinestraCompilazione: false,
    dialogMonitoraggio: false,
    snackbar: {
      text: "",
      color: "",
      value: "",
    },
  }),
  methods: {
    initialize() {
      if (this.idStrutture && this.anno) {
        this.getFinestraCompilazioneMonitoraggio();
        this.getUltimoMonitoraggio();
      }
    },
    async getFinestraCompilazioneMonitoraggio() {
      this.finestraMonitoraggi = null;
      await IstitutoRepository.getFinestraCompilazione(
        this.idStrutture,
        this.anno,
        "monitoraggi-ist"
      ).then((data) => {
        this.finestraMonitoraggi = data ?? null;
        this.chiamataApiFinestraCompilazione = true;
      });
    },
    async getUltimoMonitoraggio() {
      await MonitoraggiRepository.getUltimoMonitoraggio(
        this.idStrutture,
        this.anno
      ).then((data) => {
        if (data) {
          this.ultimoMonitoraggio = data;
          this.chiamataApiMonitoraggi = true;
        }
      });
    },
    setupPropertyAlert() {
      const dataConfermaUltimoMonitoraggio =
        this.ultimoMonitoraggio.dataConfermaMonitoraggio ?? null;
      if (this.finestraMonitoraggi && this.finestraMonitoraggi.id) {
        this.typeAlert = dataConfermaUltimoMonitoraggio ? "success" : "error";
        this.textAlert = dataConfermaUltimoMonitoraggio
          ? "L'ultimo monitoraggio è stato confermato."
          : "Dati di monitoraggio da confermare. E' necessario provvedere alla conferma dei dati di Monitoraggio.";
        this.visibile = true;
        this.dialogMonitoraggio = this.typeAlert === "error";
      } else {
        this.visibile = false;
      }
      /*} else {
        this.visibile = !(dataConfermaUltimoMonitoraggio !== null);
        this.typeAlert = this.visibile ? "info" : "success";
        this.textAlert = this.visibile
          ? "L'ultimo monitoraggio è stato confermato."
          : "";
      }*/
    },
    loadingPage() {
      let loadingPage =
        this.idStrutture &&
        this.anno &&
        this.chiamataApiFinestraCompilazione &&
        this.chiamataApiMonitoraggi &&
        (this.isRoleLegale || this.isRoleConsulenza);

      if (loadingPage) {
        this.setupPropertyAlert();
      }

      return loadingPage;
    },

    getTextDialog() {
      return (
        "<h3> Si ricorda che è necessario confermare i dati di monitoraggio in piattaforma relativi al mese di ottobre. </h3> <br /> <br />" +
        "<p style='text-align: center'>Come riportato nelle Linee Guida per il Monitoraggio destinate ai soggetti attuatori del 26 Settembre 2022:</p> <br />" +
        "<p style='text-align: center'>Il mancato assolvimento degli obblighi di monitoraggio da parte del Soggetto Attuatore, nelle modalità e nelle tempistiche definite, può comportare la sospensione delle erogazioni previste, fino al mancato riconoscimento delle assegnazioni PNRR disposte dal MUR e al recupero, anche tramite compensazione di quanto già preventivamente erogato.</p>"
      );
    },
    async confermaMonitoraggio() {
      await MonitoraggiRepository.confermaMonitoraggioMensile(
        this.idStrutture,
        this.anno,
        this.ultimoMonitoraggio.programma.capofila ? "capofila" : "istituzione"
      )
        .then(() => {
          this.dialogMonitoraggio = false;
          this.setValueSnackbar("success");
          this.$emit("afterConfermaMonitoraggi");
          this.initialize();
        })
        .catch(() => {
          this.setValueSnackbar("red accent-2");
        });
    },
    setValueSnackbar(color) {
      this.snackbar.color = color;
      this.snackbar.text =
        color === "success"
          ? "Dati di monitoraggio confermati correttamente."
          : "Errore durante l'operazione.Contattare il supporto s eil problema persiste.";
      this.snackbar.value = true;
    },
  },
};
</script>
