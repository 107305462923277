<template>
  <v-container fluid>
    <StepRendicontazioni :numero-step="1" />
    <v-main class="pb-6" v-if="quadrimestre">
      <h2 color="primary">Nuovo rendiconto di Progetto</h2>
    </v-main>
    <v-row class="pb-6">
      <v-col cols="4">
        <p>
          Periodo di riferimento <br /><br />
          <b>
            {{ quadrimestre.tipologiaQuadrimestre.id }}° Quadrimestre -
            {{ "a.s " + quadrimestre.anno }}/{{ quadrimestre.anno + 1 }}
            {{ quadrimestre.tipologiaQuadrimestre.descrizione }}</b
          >
        </p>
      </v-col>
      <v-col>
        <p>Tipologia rendiconto *</p>
        <v-radio-group
          v-model="formRendiconto.tipologiaRendiconto"
          label=""
          row
        >
          <v-radio
            v-for="tipo in tipiRendiconto"
            :key="tipo.id"
            :label="tipo.descrizione"
            :value="tipo.id"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-card v-if="corsiValidati.length">
      <v-main class="pa-0">
        <v-row class="pb-2">
          <v-col sm="3" md="3" class="d-none">
            <a class="v-btn" @click="getFileCorsiValidati('xlsx')">
              <v-icon> mdi-file-excel</v-icon>
              <span> Esporta su Excel</span>
            </a>
          </v-col>
          <v-col sm="3" md="3" class="d-none">
            <a class="v-btn" @click="getFileCorsiValidati('pdf')">
              <span>
                <v-icon> mdi-file-pdf-box </v-icon>
                Esporta su PDF
              </span></a
            >
          </v-col>
        </v-row>
      </v-main>
    </v-card>
    <v-main v-if="loadingPage">
      <v-card elevation="4">
        <v-form ref="formRendiconto" lazy-validation>
          <v-data-table
            :headers="headers"
            :items="corsiValidati"
            v-model="formRendiconto.corsiSelezionati"
            @change="calcolaSommaStudenti"
            @input="calcolaSommaStudenti"
            @keyup="calcolaSommaStudenti"
            show-select
            selectable-key="selectable"
            item-key="corso.id"
            checkbox-color="primary"
            loading-text="Caricamento dati"
            no-data-text="Non esistono corsi validati oppure tutti i corsi validati sono stati già associati ad altri rendiconti"
            ><!--eslint-disable -->
            <template v-slot:item.istituto="{ item }">
              <span v-html="getNomeIstituto(item)"></span>
            </template>
            <template v-slot:item.corso.dataInizio="{ item }">
              <span> {{ item.corso.dataInizio | formatDate }} </span>
            </template>
            <template v-slot:item.corso.dataFine="{ item }">
              <span> {{ item.corso.dataFine | formatDate }}</span>
            </template>
            <template v-slot:item.studentiConCertificato="{ item }">
              <span v-html="parseInt(item.studenti.length)" />
            </template>
            <template v-slot:item.importoCorso="{ item, index }">
              <span
                :class="{
                  'red--text font-weight-bold':
                    parseFloat(item.importoCorso) === 0,
                }"
              >
                {{ parseFloat(item.importoCorso).toFixed(2) }} €
              </span>
            </template>
            <template v-slot:item.azioni="{ item }">
              <router-link
                :to="{
                  name: 'dettaglioCorso',
                  params: {
                    capofila: capofila,
                    idCorso: item.corso.id,
                    idRendiconto: operazione === 'modifica' ? idRendiconto : -1,
                  },
                }"
              >
                Dettaglio
              </router-link>
            </template> </v-data-table
          ><!-- eslint-enable -->
          <v-card-actions v-if="corsiValidati.length">
            <div class="d-flex mb-6" v-if="quadrimestre">
              <v-row>
                <v-col class="pa-2 mr-auto">
                  <v-btn @click="goOutRendicontioSection">
                    Torna al menù principale.
                  </v-btn>
                </v-col>
                <v-col
                  class="pa-2"
                  v-if="
                    sommaStudenti >
                    programmaOrientamento.numeroAlunniCoinvolti +
                      alunniIncremento
                  "
                >
                  <v-alert type="warning" width="500">
                    Impossibile procedere. Il numero di studenti con certificato
                    dei corsi selezionati sfora il target</v-alert
                  >
                </v-col>
                <v-col v-else class="pa-2 mr-auto">
                  <v-btn
                    :disabled="formRendiconto.tipologiaRendiconto === null"
                    class="primary v-btn--outlined v-btn--tile white--text"
                    v-html="'Salva Bozza e Continua'"
                    @click="dialogConferma = true"
                  />
                  <dialog-conferma
                    :dialog.sync="dialogConferma"
                    @callback="salvaBozzaInserimentoRendiconto"
                    @close-dialog="dialogConferma = false"
                  />
                </v-col>
                <v-col class="pa-2 mr-auto">
                  <!-- disabilitato se sono in operazione inserimento e dunque non ho compilato il primo step richiesto -->
                  <v-btn
                    v-html="'Continua senza salvare'"
                    :disabled="operazione === 'inserimento'"
                    @click="goToNextStep"
                  />
                </v-col>
              </v-row>
              <v-snackbar
                :color="snackbar.color"
                v-model="snackbar.value"
                max-width="200"
                max-height="200"
              >
                {{ snackbar.text }}</v-snackbar
              >
            </div>
            <div v-else>
              <v-alert type="warning">
                Non è attiva la sezione di compilazione del quadrimestre
                corrente.
                <br />
                Impossibile procedere con la rendicontazione.
              </v-alert>
            </div>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-main>
    <v-main v-else>
      <v-progress-linear v-if="!loadingPage" indeterminate color="blue" />
    </v-main>
  </v-container>
</template>
<script>
import StepRendicontazioni from "@/components/Rendiconti/StepRendicontazioni";
import DialogConferma from "@/components/DialogConferma";
import { mapGetters, mapState } from "vuex";
import RendicontiRepository from "@/api/istituto/RendicontiRepository";
import ProgrammiRepository from "@/api/istituto/ProgrammiRepository";
export default {
  name: "InserimentoRendiconto",
  props: {
    capofila: {
      type: String,
      required: true,
    },
    // operazione 0 inserimento, 1 modifica
    operazione: {
      type: String,
      required: true,
    },
    quadrimestre: {
      type: Object,
      required: true,
    },
  },
  components: { StepRendicontazioni, DialogConferma },
  data: () => ({
    alunniIncremento: 0,
    targetIncrementato: null,
    loadingPage: false,
    corsiValidati: [],
    dialogConferma: false,
    headers: [
      { text: "ID Corso", value: "corso.id" },
      {
        text: "Istituto Scolastico",
        value: "istituto",
      },
      { text: "Data Inizio", value: "corso.dataInizio" },
      { text: "Data Fine", value: "corso.dataFine" },
      {
        text: "N° studenti con certificato",
        value: "studentiConCertificato",
      },
      { text: "Importo", value: "importoCorso" },
      { text: "Dettaglio", value: "azioni" },
    ],
    formRendiconto: {
      corsiSelezionati: [],
      currentStep: 1,
      tipologiaRendiconto: null,
    },
    tipiRendiconto: [
      { id: 0, descrizione: "Rendiconto Intermedio" },
      { id: 1, descrizione: "Rendiconto Finale" },
    ],
    snackbar: {
      color: null,
      value: false,
      text: null,
    },
    programmaOrientamento: null,
    sommaStudenti: 0,
    controlloStudentiOltreIlTarget: true,
    totStudentiConCertificato: null,
  }),
  computed: {
    ...mapState(["idStrutture", "anno", "user", "idRendiconto"]),
    ...mapGetters([
      "isReadOnly",
      "isRoleReferenteCorsi",
      "isRoleConsulenza",
      "isRoleLegale",
    ]),
  },
  watch: {
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
    capofila() {
      this.initialize();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.loadingPage = false;
      if (this.capofila && this.quadrimestre) {
        const idStrutture = this.idStrutture;
        const anno = this.anno;
        await this.getProgrammaOrientamento(idStrutture, anno);
        await this.getNumeroStudentiConCertificato(idStrutture, anno);
        if (this.operazione === "modifica") {
          await this.getCorsiValidati(idStrutture, anno, this.idRendiconto);
          await this.getRendiconto(idStrutture, this.idRendiconto);
        } else {
          await this.getCorsiValidati(idStrutture, anno);
        }
        await this.getIncrementoTarget();
        this.calcolaSommaStudenti();
      }
    },
    async getCorsiValidati(idStrutture, anno, idRendiconto) {
      const dataFineQuadrimestre = new Date(this.quadrimestre.dataFine)
        .toISOString()
        .split("T")[0];

      const dataInizioQuadrimestre = new Date(this.quadrimestre.dataInizio)
        .toISOString()
        .split("T")[0];

      // se l'id del rendiconto è settato, seleziono tutti i corsi
      if (this.idRendiconto) {
        this.corsiValidati = await RendicontiRepository.getCorsiValidati(
          idStrutture,
          anno,
          this.capofila,
          dataFineQuadrimestre,
          dataInizioQuadrimestre,
          idRendiconto
        );
        this.corsiValidati.map((element) => {
          element.selectable = element.importoCorso > 0;
        });
      } else {
        this.corsiValidati = await RendicontiRepository.getCorsiValidati(
          idStrutture,
          anno,
          this.capofila,
          dataFineQuadrimestre,
          dataInizioQuadrimestre,
          null
        );
        this.corsiValidati.map((element) => {
          element.selectable = element.importoCorso > 0;
        });
        this.loadingPage = true;
      }
    },
    formatData(data) {
      return new Date(data).toLocaleDateString("it-IT");
    },
    async salvaBozzaInserimentoRendiconto() {
      if (!this.formRendiconto.corsiSelezionati.length) {
        this.setPropertySnackbar(false, "Nessun corso è stato selezionato.");
      } else {
        let importoTotaleCorsi = 0;

        if (
          this.sommaStudenti >
          this.programmaOrientamento.numeroAlunniCoinvolti +
            this.alunniIncremento
        ) {
          this.setPropertySnackbar(
            false,
            "I corsi selezionati hanno un numero di attestati di frequenza maggiori rispetto al target del programma di orientamento"
          );
        } else {
          this.controlloStudentiOltreIlTarget = false;
          this.formRendiconto.corsiSelezionati =
            this.formRendiconto.corsiSelezionati.map((element) => {
              importoTotaleCorsi += parseFloat(element.importoCorso);
              return {
                corso: element.corso.id,
                importoCorso: element.importoCorso,
              };
            });
          this.formRendiconto.totaleImportoCalcolato =
            importoTotaleCorsi.toFixed(2);
          const formData = new FormData();
          if (this.operazione === "inserimento") {
            this.formRendiconto.quadrimestre = this.quadrimestre.id;
            formData.append("rendiconto", JSON.stringify(this.formRendiconto));
            await this.inserimentoRendiconto(formData);
          } else {
            formData.append("rendiconto", JSON.stringify(this.formRendiconto));
            await this.modificaRendiconto(formData);
          }
        }
      }
    },
    async inserimentoRendiconto(formData) {
      const idStrutture = this.$store.state.idStrutture;
      const anno = this.$store.state.anno;

      RendicontiRepository.salvaBozzaRendiconto(
        idStrutture,
        anno,
        this.capofila,
        false,
        formData
      )
        .then((data) => {
          if (data) {
            this.setPropertySnackbar(
              true,
              "La bozza del Rendiconto è stata salvata"
            );
            this.commitRendiconto(data.id);
            this.goToNextStep();
          } else {
            this.setPropertySnackbar(
              false,
              "Nessun corso è stato selezionato. Salvataggio non avvenuto"
            );
          }
        })
        .catch((reason) => {
          if (reason.response.status === 400) {
            this.setPropertySnackbar(false, reason.response.data[0]);
          } else {
            this.setPropertySnackbar(
              false,
              "Errore nell'operazione richiesta. Contattare il supporto se il problema persiste."
            );
          }
          this.formRendiconto.corsiSelezionati = [];
          this.formRendiconto.totaleImportoCalcolato = 0;
          this.initialize();
        });
    },
    async modificaRendiconto(formData) {
      const idRendiconto = this.idRendiconto;

      await RendicontiRepository.modificaRendiconto(
        this.idStrutture,
        idRendiconto,
        1,
        formData
      )
        .then((data) => {
          if (data) {
            this.setPropertySnackbar(true, "Il rendiconto è stato aggiornato");
            this.goToNextStep();
          } else {
            this.setPropertySnackbar(
              false,
              "Errore nella richiesta. Si prega di contattare il supporto."
            );
          }
        })
        .catch((reason) => {
          if (reason.response.status === 400) {
            this.setPropertySnackbar(false, reason.response.data[0]);
          } else {
            this.setPropertySnackbar(
              false,
              "Errore nella richiesta. Si prega di contattare il supporto."
            );
          }
          this.formRendiconto.totaleImportoCalcolato = 0;
          this.initialize();
        });
    },
    setPropertySnackbar(esito, testo) {
      this.snackbar.color = esito ? "success" : "red accent-2";
      this.snackbar.text = testo;
      this.snackbar.value = true;
    },
    async getFileCorsiValidati(tipoFile) {
      const corsi = { ...this.corsiValidati };
      const formData = new FormData();

      formData.append("corsi", JSON.stringify(corsi));
      await RendicontiRepository.downloadFileCorsiValidati(
        this.idStrutture,
        tipoFile,
        formData
      );
    },
    goToNextStep() {
      this.$router.push({
        name: "dichiarazioneRendiconto",
        params: { operazione: "modifica", capofila: this.capofila },
      });
    },
    goOutRendicontioSection() {
      this.commitRendiconto(null);
      if (!this.idRendiconto) {
        this.$router.push({
          name: "RendicontiView",
          params: {
            capofila: this.capofila,
          },
        });
      }
    },
    async getRendiconto(idStrutture, idRendiconto) {
      this.rendiconto = await RendicontiRepository.getSingoloRendiconto(
        idStrutture,
        idRendiconto
      );
      const corsiRendiconto = this.rendiconto.corsiRendiconto;
      // se i corsi sono associati al rendiconto, allora li memorizzo già tra i corsi selezionati
      this.corsiValidati.forEach((element) => {
        const corsoGiaSelezionato = corsiRendiconto.find(
          (corsoSelezionato) => corsoSelezionato.corso.id === element.corso.id
        );
        if (corsoGiaSelezionato) {
          this.formRendiconto.corsiSelezionati.push(element);
        }
      });
      this.formRendiconto.tipologiaRendiconto = this.rendiconto.finale ? 1 : 0;
      this.loadingPage = true;
    },
    commitRendiconto(idRendiconto) {
      this.$store.commit("setIdRendiconto", idRendiconto);
    },
    async getProgrammaOrientamento(idStrutture, anno) {
      this.programmaOrientamento = (
        await ProgrammiRepository.getProgrammi(idStrutture, anno, this.capofila)
      )[0];
    },
    calcolaSommaStudenti() {
      if (this.controlloStudentiOltreIlTarget) {
        // se il controllo è attivo
        let sum = 0;
        this.formRendiconto.corsiSelezionati.forEach((element) => {
          sum += element.studenti.length;
        });
        this.sommaStudenti = sum + this.totStudentiConCertificato;
      }
    },
    /* funzione da cui estrapolo il numero totale di studenti con certificato,
     associati a corsi rendicontati ( incluso anche quello che si sta modificando(se sono in modifica))
     */
    async getNumeroStudentiConCertificato(idStrutture, anno) {
      // se sono in modifica il conteggio non includerà quelli del rendiconto che si sta modificando
      this.totStudentiConCertificato =
        await RendicontiRepository.getNumeroStudentiConCertificato(
          idStrutture,
          anno,
          this.capofila,
          this.operazione === "modifica" ? this.idRendiconto : null
        );
    },
    async getIncrementoTarget() {
      this.targetIncrementato = await ProgrammiRepository.incrementoTarget(
        this.idStrutture,
        this.anno
      );
      this.alunniIncremento = this.targetIncrementato
        ? this.targetIncrementato.alunniTarget
        : 0;
    },
    getNomeIstituto(item) {
      if (item.corso.accordi && item.corso.accordi.length > 0) {
        let testo = "";
        item.corso.accordi.forEach((item) => {
          testo += item.istituto.denominazioneScuola + "/<br />";
        });
        return testo;
      } else {
        return "";
      }
    },
  },
};
</script>
