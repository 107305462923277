<template>
  <v-container>
    <v-card v-if="showCard" elevation="4">
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <td style="border-right: floralwhite">
                <v-card-title> Conferma Dati di Monitoraggio</v-card-title>
                <ModalElencoMonitoraggi
                  v-if="checkModalAccess()"
                  :monitoraggi="monitoraggi"
                  :read-only="
                    bottoneDisabilitato.check1 || bottoneDisabilitato.check2
                  "
                  @afterConfermaMonitoraggi="manageConfermaMonitoraggi()"
                >
                </ModalElencoMonitoraggi>
              </td>

              <td>
                <v-card-text>
                  Giorni Mancanti<br />
                  <br />
                  <h2>{{ giorniMancantiMonitoraggio }}</h2>
                </v-card-text>
              </td>
              <td>
                <v-card-text>
                  Dati di monitoraggio <br />
                  mensili al <br />
                  <h2>{{ dataMonitoraggioAl }}</h2></v-card-text
                >
              </td>

              <td>
                <v-card-text>
                  Data ultima conferma <br />
                  <br />
                  <h2 v-if="dataUltimaConferma">
                    {{ dataUltimaConferma }}
                  </h2>
                </v-card-text>
              </td>
              <td>
                <v-card-text>
                  Data termine <br />
                  <br />
                  <h2>
                    <b> {{ dataTermineMonitoraggio }}</b>
                  </h2>
                </v-card-text>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div v-if="loadingAlertMonitoraggi">
        <AlertMonitoraggi
          @afterConfermaMonitoraggi="manageConfermaMonitoraggi"
        />
      </div>
    </v-card>
  </v-container>
</template>

<script>
//import DialogConferma from "@/components/DialogConferma.vue";
import ProgrammiRepository from "@/api/istituto/ProgrammiRepository";
import MonitoraggiRepository from "@/api/istituto/MonitoraggiRepository";
import { mapGetters, mapState } from "vuex";
import IstitutoRepository from "@/api/istituto/IstitutoRepository";
import ModalElencoMonitoraggi from "@/components/Monitoraggi/ModaleElencoMonitoraggiIstituzione.vue";
import AlertMonitoraggi from "@/components/Monitoraggi/AlertMonitoraggi.vue";

export default {
  name: "ConfermaMonitoraggio",
  components: { AlertMonitoraggi, ModalElencoMonitoraggi /*, DialogConferma*/ },
  computed: {
    ...mapState(["idStrutture", "anno"]),
    ...mapGetters([
      "isRoleConsulenza",
      "isRoleIstituto",
      "isRoleLegale",
      "isReadOnly",
    ]),
  },
  watch: {
    anno() {
      this.initialize();
    },
    idStrutture() {
      this.initialize();
    },
  },
  data: () => ({
    giorniMancantiMonitoraggio: null,
    dataMonitoraggioAl: null,
    dataTermineMonitoraggio: null,
    bottoneDisabilitato: {
      check1: true,
      check2: true,
    },
    dialogConferma: false,
    showCard: false,
    dialogConfermaMonitoraggio: false,
    datiRiepilogo: [],
    ultimoMonitoraggio: null,
    dataUltimaConferma: null,
    programma: null,
    monitoraggi: [],
    snackbar: {
      text: null,
      color: "green",
      value: false,
    },
    finestraCompilazione: null,
    checkBtnDisabled1: true,
    loadingAlertMonitoraggi: false,
  }),
  props: {
    capofila: {
      type: String,
      required: true,
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      let idStrutture = this.$store.state.idStrutture;
      let anno = this.$store.state.anno;
      let capofila = this.capofila;
      this.getProgrammaOrientamento(idStrutture, anno, capofila);
      this.loadingAlertMonitoraggi = this.isRoleConsulenza || this.isRoleLegale;
    },
    async getProgrammaOrientamento(idStrutture, anno, capofila) {
      await ProgrammiRepository.getProgrammi(idStrutture, anno, capofila).then(
        (data) => {
          if (data && data.length > 0) {
            this.programma = data[0];
            this.getFinestraCompilazioneMonitoraggi(idStrutture, anno);
            this.getMonitoraggiProgramma(idStrutture);
            this.getDateToShow();
            this.showCard = true;
          } else {
            this.showCard = false;
          }
        }
      );
    },
    async getFinestraCompilazioneMonitoraggi(idStrutture, anno) {
      await IstitutoRepository.getFinestraCompilazione(
        idStrutture,
        anno,
        "monitoraggi-ist"
      ).then((data) => {
        if (data) {
          // la compilazione del monitoraggio è attiva
          this.giorniMancantiMonitoraggio = "Attivo";
          this.bottoneDisabilitato.check1 = false;
          this.finestraCompilazione = data;
        } else {
          // calcolo i giorni mancanti all'apertura
          const dateOdierna = new Date();
          const dataInizioMonitoraggio = new Date(
            dateOdierna.getFullYear(),
            dateOdierna.getMonth() + 1,
            1
          );
          const diffDay = Math.abs(dateOdierna - dataInizioMonitoraggio);
          this.giorniMancantiMonitoraggio = Math.ceil(
            diffDay / (1000 * 60 * 60 * 24)
          );
        }
      });
    },
    async getMonitoraggiProgramma(idStrutture) {
      this.monitoraggi = await MonitoraggiRepository.getMonitoraggiProgramma(
        idStrutture,
        this.programma.id
      );
      const lengthArray = this.monitoraggi.length;
      if (lengthArray) {
        const ultimoMonitoraggioConfermato = this.monitoraggi.findLast(
          (element) => element.dataConfermaMonitoraggio
        );

        if (ultimoMonitoraggioConfermato != null) {
          const dataUltimaConferma = new Date(
            ultimoMonitoraggioConfermato.dataConfermaMonitoraggio
          );
          this.dataUltimaConferma = dataUltimaConferma.toLocaleDateString();

          const meseCorrente = new Date().getMonth();
          this.bottoneDisabilitato.check2 =
            dataUltimaConferma.getMonth() === meseCorrente;
        } else {
          // non esistono monitoraggi
          this.bottoneDisabilitato.check2 = false;
        }
      } else {
        this.bottoneDisabilitato.check2 = false;
      }
    },
    getDateToShow() {
      const dateOdierna = new Date();

      this.dataTermineMonitoraggio = new Date(
        dateOdierna.getFullYear(),
        dateOdierna.getMonth(),
        10
      ).toLocaleDateString();

      // data da mostrare nella colonna data di monitoraggio al
      this.dataMonitoraggioAl = new Date(
        dateOdierna.getFullYear(),
        dateOdierna.getMonth() - 1,
        30
      ).toLocaleDateString();
    },
    manageConfermaMonitoraggi() {
      /**
       * reinizializzo il contenuto del component qualora vengono confermati i dati di monitoraggio
       */
      this.showCard = false;
      this.loadingAlertMonitoraggi = false;
      this.monitoraggi = [];
      setTimeout(() => {
        this.initialize();
      }, 1000);
    },
    checkModalAccess() {
      return (
        this.monitoraggi.length > 0 &&
        (this.isRoleLegale || this.isRoleConsulenza)
      );
    },
  },
};
</script>
<style scoped></style>
