<template>
  <v-container>
    <BreadcrumbComponent
      :breadcrumbs="breadcrumbs"
      v-if="capofila && !isPartner"
    />
    <v-row>
      <v-col cols="12">
        <h1>
          Censimento corsi, erogazione formazione e Attestati di frequenza
        </h1>
        <p>
          Qui è possibile visualizzare l'elenco delle azioni da compiere ed
          inserire nuove attività
        </p>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-col>
        <AlertErroriCf />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card elevation="4">
          <v-card-title>
            <v-icon class="mr-2">mdi-clipboard-check-outline</v-icon>
            Censimento corsi
          </v-card-title>
          <v-card-text>
            Sezione in cui il Referente di Corso può inserire i dati inerenti i
            singoli corsi.
          </v-card-text>
          <br />
          <v-card-actions>
            <v-spacer></v-spacer>
            <router-link
              class="v-btn"
              :to="`/scrivania/${capofila}/corsi/censimento-corsi`"
            >
              Vai <v-icon>mdi-arrow-right-thin</v-icon>
            </router-link>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col>
        <v-card elevation="8">
          <v-card-title>
            <v-icon class="mr-2">mdi-clipboard-check-outline</v-icon>
            Erogazione formazione
          </v-card-title>
          <v-card-text>
            Sezione in cui il Referente di Corso può registrare le presenze
            degli studenti per ogni singola lezione e successivamente terminare
            il singolo corso.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <router-link
              class="v-btn"
              disabled
              :to="`/scrivania/${capofila}/corsi/erogazione-corsi`"
            >
              Vai <v-icon>mdi-arrow-right-thin</v-icon>
            </router-link>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col col="6">
        <v-card elevation="8">
          <v-card-title>
            <v-icon class="mr-2">mdi-clipboard-check-outline</v-icon>
            Attestati di frequenza
          </v-card-title>
          <v-card-text>
            Sezione in cui il Referente di Corso o il Referente per il Programma
            potranno firmare e scaricare gli Attestati di Frequenza degli
            studenti che hanno raggiunto il numero minimo di ore di frequenza
            per il rilascio.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <router-link
              class="v-btn"
              disabled
              :to="`/scrivania/${capofila}/corsi/certificati`"
            >
              Vai <v-icon>mdi-arrow-right-thin</v-icon>
            </router-link>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col col="6">
        <v-row>
          <v-col>
            <v-card elevation="8">
              <v-card-title>
                <v-icon class="mr-2">mdi-clipboard-check-outline</v-icon>
                Questionari di valutazione
              </v-card-title>
              <v-card-text>
                Sezione in cui il Legale Rappresentante, il referente per il
                programma e il referente di corso possono scaricare i file Excel
                con le risposte ai questionari forniti da alunni e alunne
                partecipanti ai corsi di orientamento.
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <router-link
                  class="v-btn"
                  :to="`/scrivania/${capofila}/corsi-questionari`"
                >
                  Vai <v-icon>mdi-arrow-right-thin</v-icon>
                </router-link>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" />
      <v-col cols="6">
        <div class="px-4">
          <h2 class="my-2">Esegui un test di firma</h2>
          <p class="font-italic">
            Il test consiste nel download e nell'esecuzione di un file JNLP che
            non è altro che l’avviatore del programma Confirma.<br />
            Per aprire correttamente questo tipo di file (e il programma al
            quale esso è associato) è indispensabile disporre di un ambiente
            Java preventivamente installato sul computer.
          </p>
          <p class="font-italic">
            Raccomandiamo fortemente la <b>versione 8_202</b> di Java.<br />
            Segui il
            <a
              target="_blank"
              href="https://orientamento2026-backend.cineca.it/uploads/2022/istituto/Manuale_per_installazione_di_JAVA.pdf"
              ><b>MANUALE</b></a
            >
            per scaricare, installare e configurare la giusta versione di Java.
          </p>
          <v-btn class="error" @click="getTestFirma">
            Scarica test
            <v-icon right class="white--text"> mdi-tray-arrow-down </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import CorsiRepository from "@/api/istituto/CorsiRepository";
import { mapState } from "vuex";
import AlertErroriCf from "@/components/AlertErroriCf";

export default {
  name: "ScrivaniaView",
  data() {
    return {};
  },
  components: { AlertErroriCf, BreadcrumbComponent },
  props: {
    capofila: {
      type: String,
      required: true,
    },
  },
  computed: {
    breadcrumbs: function () {
      if (this.capofila) {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania/${this.capofila}`,
            exact: true,
            link: true,
          },
          {
            text: "Censimento corsi, erogazione formazione e attestati di frequenza",
            disabled: true,
            to: `/scrivania/${this.capofila}/corsi`,
            exact: true,
            link: true,
          },
        ];
      }
      return [];
    },
    ...mapState(["idStrutture"]),
  },
  methods: {
    async getTestFirma() {
      await CorsiRepository.downloadTestFirma();
    },
  },
};
</script>

<style scoped></style>
