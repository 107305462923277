<template>
  <v-container>
    <BreadcrumbComponent :breadcrumbs="breadcrumbs" v-if="capofila" />
    <v-row class="">
      <v-col>
        <h1>Attestati di frequenza</h1>
      </v-col>
    </v-row>
    <template v-if="abilitazione">
      <v-alert
        dense
        type="warning"
        v-if="!abilitazione.idRuolo"
        density="compact"
        class="mt-2"
      >
        ATTENZIONE: Il legale rappresentante non ha abilitato nessun ruolo alla
        firma degli attestati. <br />Abilitare uno dei due ruoli nella sezione
        "Gestione utenti" prima di procedere.
      </v-alert>
      <v-alert dense type="info" density="compact" v-else class="mt-2">
        Il legale rappresentante ha abilitato alla firma gli utenti con il ruolo
        di
        <span v-if="abilitazione.idRuolo === 5">REFERENTE DI CORSO</span>
        <span v-else>REFERENTE DI PROGRAMMA</span>
      </v-alert>
    </template>
    <template v-else>
      <v-alert dense type="warning" density="compact" class="mt-2">
        ATTENZIONE: Il legale rappresentante non ha abilitato nessun ruolo alla
        firma degli attestati. <br />Abilitare uno dei due ruoli nella sezione
        "Gestione utenti" prima di procedere.
      </v-alert>
    </template>
    <v-row>
      <v-col>
        <v-alert border="left" colored-border type="info" elevation="2">
          <p>
            Solamente il Referente per il Programma può <b>validare</b> i corsi
            firmati
          </p>
          <p>
            La selezione del corso per la validazione sarà possibile solamente
            dopo che il <b>Referente abilitato</b> dal legale rappresentate ha
            <b>firmato</b> tutti gli attestati degli studenti con oltre il 70%
            di presenza nel corso.
          </p>
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <template>
          <v-alert prominent type="error" v-if="showAlertErrori">
            E’ stata riscontrata la presenza di errori durante il processo di
            firma degli attestati nei seguenti corsi:
            {{ listaCorsiErrori }}. Nel caso di mancata correzione dei dati non
            sarà possibile validare i corsi.
          </v-alert>
        </template>
      </v-col>
    </v-row>
    <div class="pa-4" style="background-color: gainsboro">
      <v-row class="pb-4">
        <v-col>
          <h4>Anno Scolastico {{ this.anno }}/{{ this.anno + 1 }}</h4>
        </v-col>
      </v-row>
      <v-card elevation="2" class="px-4 pb-4" v-if="idStrutture !== null">
        <v-row class="mt-2">
          <v-col>
            <h4 v-if="this.capofila === 'istituzione'">
              Lista dei corsi terminati
            </h4>
            <h4 v-else class="font-weight-bold">
              Lista dei corsi terminati in convenzione da <u>Capofila</u>
            </h4>
          </v-col>
        </v-row>

        <div class="mb-6">
          <v-row class="my-4">
            <v-col col="8" class="">
              <v-text-field
                solo
                v-model="ricerca"
                append-icon="mdi-magnify"
                label="Cerca per ID Corso"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                :headers="headers"
                :items="corsiTerminati"
                :item-key="corsi.id"
                :search="ricerca"
                :loading="mostra"
                loading-text="Caricamento dati"
              >
                <!-- eslint-disable -->
                <template v-slot:item.istituto="{ item }">
                  <span v-html="getNomeIstituto(item)"></span>
                </template>
                <template v-slot:item.stato="{ item }">
                  <span> Terminato </span>
                </template>
                <template v-slot:item.dataInizio="{ item }">
                  <span v-html="convertData(item.dataInizio)" />
                </template>
                <template v-slot:item.dataFine="{ item }">
                  <span v-html="convertData(item.dataFine)" />
                </template>
                <template v-slot:item.validazione="{ item }">
                  <span v-if="!mostra" v-html="statoValidazione(item)" />
                </template>
                <template v-slot:item.actions="{ item }">
                  <router-link
                    :to="{
                      name: 'gestisci-certificati-corsi',
                      params: { capofila: capofila, idCorso: item.id },
                    }"
                  >
                    Gestisci attestato
                  </router-link>
                </template>
                <template v-slot:item.seleziona="{ item }">
                  <v-checkbox
                    v-if="!mostra"
                    :disabled="checkboxDisabilitato(item)"
                    @change="selezionaCorso(item)"
                  ></v-checkbox>
                </template>
                <!-- eslint-enable -->
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>
    <v-row class="mt-4">
      <v-col class="d-flex flex-row-reverse">
        <v-btn
          v-if="
            (isRoleReferenteProgramma || isRoleConsulenza) &&
            finestraCompilazione
          "
          class="primary"
          @click="dialogSalva = true"
          :disabled="!corsiSelezionati.length > 0 || validazioneButton"
        >
          Valida i corsi
        </v-btn>
      </v-col>
    </v-row>
    <dialog-conferma
      @callback="validazione"
      :dialog.sync="dialogSalva"
      @close-dialog="dialogSalva = false"
    />
    <v-snackbar v-model="snackbar" shaped :color="snackbarColor">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import CorsiRepository from "@/api/istituto/CorsiRepository";
import DialogConferma from "@/components/DialogConferma";
import IstitutoRepository from "@/api/istituto/IstitutoRepository";

export default {
  name: "certificatiCorsiView",
  components: { BreadcrumbComponent, DialogConferma },
  data: () => ({
    ricerca: null,
    mostra: false,
    showAlertErrori: false,
    listaCorsiErrori: [],
    validazioneButton: false,
    abilitazione: null,
    validato: 0,
    arrayCertificati: [],
    dialogSalva: null,
    dialogProsegui: null,
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    idUtente: 0,
    loadingCertificati: false,
    inserimento: true,
    isDisabled: true,
    isCapofila: false,
    corsi: [],
    corsiTerminati: [],
    corsiSelezionati: [],
    finestraCompilazione: null,
    headers: [
      {
        //text: "Seleziona",
        sortable: false,
        value: "seleziona",
      },
      { text: "ID corso", value: "id" },
      { text: "CUP", value: "accordo.programmaOrientamento.cup" },
      {
        text: "Stato del corso",
        align: "start",
        sortable: false,
        value: "stato",
      },
      { text: "Istituto Scolastico", value: "istituto" },
      { text: "Data Inizio", value: "dataInizio" },
      { text: "Data Fine", value: "dataFine" },
      { text: "Numero studenti", align: "center", value: "numeroStudenti" },
      { text: "Stato di firma", value: "validazione" },
      { text: "Azioni", value: "actions", sortable: false },
    ],
  }),
  props: {
    capofila: {
      type: String,
      required: true,
    },
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapState(["idStrutture", "anno", "user"]),
    ...mapGetters([
      "isRoleReferenteCorsi",
      "isRoleReferenteProgramma",
      "isRoleReferenteIstituto",
      "isRoleReferenteIstitutoCorso",
      "isRoleConsulenza",
      "isReadOnly",
    ]),
    loading: function () {
      return this.loadingCertificati;
    },
    breadcrumbs: function () {
      if (this.capofila) {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania/${this.capofila}`,
            exact: true,
            link: true,
          },
          {
            text: "Censimento, erogazione e attestati di frequenza",
            disabled: false,
            to: `/scrivania/${this.capofila}/corsi`,
            exact: true,
            link: true,
          },
          {
            text: "Attestati di frequenza",
            disabled: true,
            to: `/scrivania/${this.capofila}/corsi/certificati`,
            exact: true,
            link: true,
          },
        ];
      }
      return [];
    },
  },
  watch: {
    validato() {
      this.initialize();
    },
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      if (this.idStrutture != null && this.anno != null) {
        this.findCorsiTerminati();
        this.getAbilitatoAllaFirma();
        this.getInfoCertificati();
        this.corsiCertificatiErrati();
        this.getFinestraCompilazione();
      }
    },
    async getFinestraCompilazione() {
      const finestraCompilazione =
        await IstitutoRepository.getFinestraCompilazione(
          this.idStrutture,
          this.anno,
          "corsi-orientamento-ist"
        );
      if (finestraCompilazione !== "") {
        this.finestraCompilazione = finestraCompilazione;
      }
    },
    async findCorsiTerminati() {
      this.loadingCertificati = true;
      let idStrutture = this.$store.state.idStrutture;
      let anno = this.$store.state.anno;
      let capofila = this.capofila;

      this.corsi = await CorsiRepository.getCorsi(idStrutture, anno, capofila);
      this.loadingCertificati = false;
      this.corsiTerminati = this.corsi.filter(
        (item) => item.terminato === true
      );
    },
    convertData(data) {
      if (data) {
        return new Date(data).toLocaleDateString("nl-NL", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        });
      }
    },
    selezionaCorso(item) {
      if (this.corsiSelezionati.includes(item.id)) {
        let index = this.corsiSelezionati.indexOf(item.id);
        this.corsiSelezionati.splice(index, 1);
      } else {
        this.corsiSelezionati.push(item.id);
      }
    },
    async validazione() {
      let corsiSelezionati = { ...this.corsiSelezionati };
      const data = new FormData();
      data.append("corsiSelezionati", JSON.stringify(corsiSelezionati));
      try {
        await CorsiRepository.validaCorsi(this.idStrutture, data);
        this.validato += 1;
        this.snackbarText = "Validazione corsi effettuata con successo";
        this.snackbarColor = "success";
        this.snackbar = true;
        this.validazioneButton = true;
      } catch (e) {
        console.log(e);
        if (e.response.status === 401) {
          this.snackbarText =
            "Errore: Selezionare i corsi prima di procedere alla convalida";
          this.snackbarColor = "red accent-2";
        }
        if (e.response.status === 409) {
          this.snackbarText =
            "Errore: il corso con id " +
            e.response.data.idCorso +
            " ha " +
            e.response.data.certificatiValidi +
            " attestati DA FIRMARE, ma soltanto " +
            e.response.data.certificatiFirmati +
            " attestati FIRMATI!";
          this.snackbarColor = "red accent-2";
        } else {
          this.snackbarText =
            "Problema durante la convalida del corso, contattare il supporto se il problema persiste";
          this.snackbarColor = "red accent-2";
        }
        this.snackbar = true;
      }
    },
    async getAbilitatoAllaFirma() {
      this.loadingCertificati = true;
      this.abilitazione = await IstitutoRepository.getAbilitaFirma(
        this.idStrutture,
        this.anno
      );
      this.loadingCertificati = false;
    },
    statoValidazione(corso) {
      this.loadingCertificati = true;
      let certificato = this.arrayCertificati.find(
        (element) => element["id"] === corso.id
      );
      if (certificato) {
        if (
          certificato.certificatiReferente !==
          certificato["certificatiValidi"].data.ottenuti
        ) {
          this.loadingCertificati = false;
          return "Da firmare";
        }
      }
      this.loadingCertificati = false;
      return corso.validato ? "Validato" : "Da validare";
    },
    checkboxDisabilitato(corso) {
      this.loadingCertificati = true;
      if (corso.validato) {
        return true;
      }
      if (this.listaCorsiErrori.includes(corso.id)) {
        return true;
      }
      let certificato = this.arrayCertificati.find(
        (element) => element["id"] === corso.id
      );
      if (certificato) {
        if (
          certificato.certificatiReferente !==
          certificato["certificatiValidi"].data.ottenuti
        ) {
          return true;
        }
      }
      this.loadingCertificati = false;
      return false;
    },
    async getInfoCertificati() {
      this.mostra = true;
      this.loadingCertificati = true;
      this.arrayCertificati = await CorsiRepository.getNumeriCertificati(
        this.idStrutture,
        this.anno,
        this.capofila
      );
      this.mostra = false;
      this.loadingCertificati = false;
    },
    async corsiCertificatiErrati() {
      this.loadingCertificati = true;
      this.listaCorsiErrori = await IstitutoRepository.getCertificatiErrati(
        this.idStrutture,
        this.anno
      );
      this.loadingCertificati = false;
      if (this.listaCorsiErrori.length) this.showAlertErrori = true;
    },
    getNomeIstituto(corso) {
      if (corso.accordi && corso.accordi.length > 0) {
        let testo = "";
        corso.accordi.forEach((item) => {
          testo += item.istituto.denominazioneScuola + "/<br />";
        });
        return testo;
      } else {
        return corso.accordo.istituto.denominazioneScuola;
      }
    },
  },
};
</script>

<style scoped></style>
