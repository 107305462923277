<template>
  <div class="mt-10">
    <v-card
      v-if="valoriAssegnazioniPeriodi"
      elevation="8"
      class="mx-auto mt-4"
      :loading="loadingTarget"
    >
      <v-container fluid>
        <v-row justify="center">
          <v-col cols="12">
            <h2 class="mb-4">Assegnazione per periodo</h2>

            <h4>Seleziona il Periodo</h4>

            <v-tabs
              v-model="activeTab"
              background-color="primary"
              dark
              show-arrows
              class="flex-wrap"
            >
              <v-tab
                v-for="(periodo, index) in valoriAssegnazioniPeriodi"
                :key="index"
                style="white-space: nowrap; font-size: small"
              >
                <strong>
                  <div>
                    {{ periodo.descrizionePeriodo.split(" / ")[0] }}
                  </div>
                  <div>
                    {{ periodo.descrizionePeriodo.split(" / ")[1] }}
                  </div>
                </strong>
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="activeTab">
              <v-tab-item
                v-for="(periodo, index) in valoriAssegnazioniPeriodi"
                :key="index"
              >
                <v-card elevation="2" class="mt-2 pa-5">
                  <h4 class="mb-2">Assegnazione</h4>
                  <v-row>
                    <v-col>
                      <p>Numero Alunni Assegnati</p>
                      <p>{{ periodo.numeroAlunniAssegnati }}</p>
                    </v-col>
                    <v-col>
                      <p>Assegnazione Finanziaria</p>
                      <p>
                        {{ periodo.assegnazioneFinanziaria | formatNumber }}€
                      </p>
                    </v-col>
                  </v-row>

                  <h4 class="mb-2">Numero alunni realizzati</h4>
                  <v-row>
                    <v-col>
                      <p>Numero Alunni Censiti</p>
                      <p>{{ periodo.numeroAlunniCensiti }}</p>
                    </v-col>
                    <v-col>
                      <p>Numero Alunni con Attestato</p>
                      <p>{{ periodo.numeroAlunniConAttestato }}</p>
                    </v-col>
                  </v-row>

                  <h4 class="mb-2">Alunni con Attestato:</h4>
                  <div
                    class="d-flex justify-content-end align-items-center mb-2"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          class="ml-2 info-icon"
                          color="gray"
                        >
                          mdi-information
                        </v-icon>
                      </template>
                      <span>
                        Percentuale di alunni con attestato in relazione al
                        numero di alunni assegnati per periodo
                      </span>
                    </v-tooltip>

                    <span class="ml-2 numero-alunni">
                      {{ periodo.numeroAlunniConAttestato }}/{{
                        periodo.numeroAlunniAssegnati
                      }}
                    </span>
                  </div>

                  <v-progress-linear
                    :value="
                      calculatePercentage(
                        periodo.numeroAlunniConAttestato,
                        periodo.numeroAlunniAssegnati
                      )
                    "
                    class="rounded-progress"
                    color="light-blue"
                    height="24"
                    :rounded="true"
                  >
                    <template v-slot:default>
                      <span class="progress-label">
                        {{
                          calculatePercentage(
                            periodo.numeroAlunniConAttestato,
                            periodo.numeroAlunniAssegnati
                          )
                        }}%
                      </span>
                    </template>
                  </v-progress-linear>

                  <h4 class="mb-2 mt-2">Rendicontazione</h4>
                  <v-row>
                    <v-col>
                      <p>Importo Rendicontabile</p>
                      <p>{{ periodo.importoRendicontabile | formatNumber }}€</p>
                    </v-col>
                    <v-col>
                      <p>Importo Rendicontato</p>
                      <p>{{ periodo.importoRendicontato | formatNumber }}€</p>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card v-else elevation="8" class="text-center">
      <v-container>
        <v-row justify="center">
          <v-col cols="12">
            <v-progress-circular
              indeterminate
              color="primary"
              size="64"
            ></v-progress-circular>
            <p>Caricamento assegnazioni...</p>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import IstitutoRepository from "@/api/istituto/IstitutoRepository";

export default {
  name: "AssegnazionePerPeriodo",
  data: () => ({
    loadingAssegnazione: true,
    valoriAssegnazioniPeriodi: null,
    activeTab: 0, // Tab attivo
  }),
  computed: {
    ...mapState(["idStrutture", "anno"]),
  },
  watch: {
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.idStrutture != null && this.anno != null) {
        this.loadingAssegnazione = true;
        this.getAssegnazioniPeriodi();
      }
    },
    async getAssegnazioniPeriodi() {
      this.valoriAssegnazioniPeriodi =
        await IstitutoRepository.getAssegnazioniPeriodi(
          this.idStrutture,
          this.anno
        );
      this.loadingAssegnazione = false;
    },
    calculatePercentage(alunniConAttestato, alunniAssegnati) {
      if (alunniAssegnati === 0) return 0;
      return Math.round((alunniConAttestato / alunniAssegnati) * 100);
    },
  },
};
</script>

<style scoped>
.rounded-progress {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress-label {
  color: black;
  font-weight: bold;
  font-size: 14px;
  padding-right: 8px;
}

.info-icon {
  font-size: 18px;
  margin-right: 8px;
}

.numero-alunni {
  font-size: 14px;
  color: #555;
}
</style>
