<template>
  <v-dialog
    max-width="600"
    v-model="dialogModificaStudente"
    :scrollable="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-on="on"
        v-bind="attrs"
        class="mx-3"
        fab
        x-small
        color="primary"
        :disabled="readOnly"
        @click="dialogModificaStudente = true"
        ><v-icon> mdi-pencil-outline </v-icon>
      </v-btn>
    </template>
    <v-card v-if="dialogModificaStudente">
      <v-card-title> Modifica studente</v-card-title>
      <v-card-text style="margin-top: 2%">
        <v-form v-if="loadingForm" ref="formDatiStudenti" v-model="formValida">
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                label="Nome *"
                v-model="formDatiStudente.nome"
                :rules="validazioneCampoNecessario()"
                @change="validazioneCampoNecessario()"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                label="Cognome *"
                v-model="formDatiStudente.cognome"
                :rules="validazioneCampoNecessario()"
                @change="validazioneCampoNecessario()"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6" v-if="listaComuni">
              <v-autocomplete
                :items="listaComuni"
                :rules="validazioneCampoNecessario()"
                v-model="formDatiStudente.comuneResidenzaId"
                item-text="name_it"
                item-value="id"
                label="Comune di residenza *"
                @change="validazioneCampoNecessario()"
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-select
                v-if="classiStudente"
                v-model="formDatiStudente.classe"
                :items="classiStudente"
                :rules="validazioneCampoNecessario()"
                item-text="descrizione"
                item-value="id"
                label="Classe *"
                required
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="formDatiStudente.istituto"
                :items="istitutiScolasticiStudente"
                item-text="text"
                item-value="value"
                label="Codice Scuola *"
                :rules="validazioneCampoNecessario()"
                required
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
        <v-row>
          <v-col cols="5">
            <v-btn
              color="primary"
              @click="dialogConfermaOperazione = true"
              :disabled="readOnly || !formValida"
            >
              Modifica Studente
            </v-btn>
          </v-col>
          <v-col cols="5">
            <v-btn color="warning" @click="closeDialogModificaStudente()">
              Chiudi
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <dialog-conferma
        @callback="updateStudente($event)"
        :dialog.sync="dialogConfermaOperazione"
        @close-dialog="dialogConfermaOperazione = false"
      ></dialog-conferma>
    </v-card>
  </v-dialog>
</template>
<style scoped>
.modal-content {
  margin: 5%;
}
</style>
<script>
import DialogConferma from "@/components/DialogConferma.vue";
import CorsiRepository from "@/api/istituto/CorsiRepository";
import { mapState } from "vuex";

export default {
  name: "modalModificaStudente",
  components: { DialogConferma },
  props: {
    studente: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      required: true,
    },
    listaComuni: {
      type: Array,
      required: true,
    },
    classiStudente: {
      type: Array,
      required: true,
    },
    istitutiScolasticiStudente: {
      type: Array,
      required: true,
    },
    idCorso: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    loadingComponent: false,
    dialogConfermaOperazione: false,
    dialogModificaStudente: false,
    formDatiStudente: {
      id: null,
      nome: null,
      cognome: null,
      classe: null,
      comuneResidenzaId: null,
      istituto: null,
    },
    formValida: true,
    loadingForm: false,
    snackbar: {
      text: "",
      valu: false,
      color: "",
    },
  }),
  created() {
    this.initialize();
  },
  computed: {
    ...mapState(["idStrutture", "anno"]),
  },
  watch: {
    idStrutture() {
      this.$destroy();
    },
    anno() {
      this.$destroy();
    },
  },
  methods: {
    initialize() {
      if (
        this.studente &&
        this.listaComuni.length &&
        this.classiStudente.length &&
        this.istitutiScolasticiStudente.length
      ) {
        this.loadingComponent = true;
        this.setupFormStudente();
      }
    },
    validazioneCampoNecessario() {
      return [(v) => !!v || "Campo richiesto"];
    },
    validateField() {
      this.formValida = this.$refs.formDatiStudenti.validate();
    },
    async updateStudente() {
      if (this.$refs.formDatiStudenti.validate()) {
        let formDatiStudente = this.setupRequestModificaStudente();
        let idStudente = formDatiStudente.id;
        let idCorso = this.idCorso;
        let idStrutture = this.idStrutture;
        let formBody = new FormData();
        formBody.append("studente", JSON.stringify(formDatiStudente));

        await CorsiRepository.modificaStudente(
          idStrutture,
          idCorso,
          idStudente,
          formBody
        )
          .then(() => {
            this.dialogModificaStudente = false;
            this.$emit("update-studente");
          })
          .catch((reason) => {
            switch (reason.response.status) {
              case 500:
                this.setSnackbar(
                  "Un errore generico è stato riscontrato durante l'operazione. Contattare il supporto se il problema persiste",
                  "red accent-2"
                );
                break;
              case 400:
                this.setSnackbar(reason.response.data, "red accent 2");
                break;
            }
          });
      } else {
        this.setSnackbar(
          "Verificare la compilazione di tutti i campi",
          "red accent-2"
        );
      }
    },
    setupFormStudente() {
      this.formDatiStudente.id = this.studente.idStudente;
      this.formDatiStudente.nome = this.studente.nome;
      this.formDatiStudente.cognome = this.studente.cognome;
      let comuneResidenza = this.listaComuni.find((value) => {
        return parseInt(value.id) === this.studente.comuneResidenzaId;
      });
      this.formDatiStudente.comuneResidenzaId = comuneResidenza.id ?? null;

      let classe = this.classiStudente.find((value) => {
        return value.descrizione === this.studente.classe;
      });
      this.formDatiStudente.classe = classe.id ?? null;
      this.loadingForm = true;

      let istitutoScolastico = this.istitutiScolasticiStudente.find(
        (element) => {
          return this.studente.idIstituto === element.value;
        }
      );
      this.formDatiStudente.istituto = istitutoScolastico.value ?? null;
    },
    closeDialogModificaStudente() {
      this.dialogModificaStudente = false;
      this.$emit("close-dialog");
    },
    setupRequestModificaStudente() {
      let formDatiStudente = this.formDatiStudente;
      formDatiStudente.nome =
        formDatiStudente.nome[0].toUpperCase() +
        formDatiStudente.nome.slice(1).toLowerCase();
      formDatiStudente.cognome =
        formDatiStudente.cognome[0].toUpperCase() +
        formDatiStudente.cognome.slice(1).toLowerCase();

      return formDatiStudente;
    },
    setSnackbar(text, color) {
      this.snackbar.text = text;
      this.snackbar.color = color;
      this.snackbar.value = true;
    },
  },
};
</script>
