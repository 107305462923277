<template>
  <v-data-table
    :headers="headers"
    :items="utenti"
    sort-by="username"
    class="elevation-1"
    :loading="loadingUtenti"
    loading-text="Caricamento in corso... Attendere"
    v-if="$store.getters.hasPermessoGestioneUtenti && ruolo"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>{{ tableTitle }}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <template v-if="getRuolo === 5 || getRuolo === 6">
          <v-checkbox
            v-if="isRoleLegale || isRoleConsulenza"
            label="Abilitato alla firma degli attestati"
            :value="getRuolo === 5 ? 5 : 6"
            v-model="abilitazione.idRuolo"
            :disabled="
              abilitazione.idRuolo && getRuolo !== abilitazione.idRuolo
            "
            @click="updateAbilitaFirma()"
          ></v-checkbox>
        </template>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="800px">
          <template v-slot:activator="{ on, attrs }">
            <template v-if="!isRoleMinistero && !bloccaInserimento">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                Crea nuovo utente
              </v-btn>
            </template>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formDialogTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.anagrafica.nome"
                      label="Nome"
                      :disabled="isRuoloLegale || isRuoloProgramma"
                      @input="generaUsername"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.anagrafica.cognome"
                      label="Cognome"
                      :disabled="isRuoloLegale || isRuoloProgramma"
                      @input="generaUsername"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItem.anagrafica.codiceFiscale"
                      label="Codice Fiscale"
                      :disabled="isRuoloLegale || isRuoloProgramma"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItem.anagrafica.email"
                      label="Email"
                      :disabled="isRuoloLegale || isRuoloProgramma"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItem.username"
                      label="username"
                      :disabled="isRuoloLegale || isRuoloProgramma"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-checkbox
                      v-if="getRuolo !== 7"
                      v-model="editedItem.abilitato"
                      label="Abilitato"
                      :disabled="isRuoloLegale || isRuoloProgramma"
                      :readonly="editedIndex === -1"
                    ></v-checkbox>
                  </v-col>
                  <v-row>
                    <v-col>
                      <small v-if="isRuoloLegale">
                        Il legale rappresentante può ricoprire
                        <b>SOLO UN RUOLO</b>
                        tra referente di programma e referente di corso
                      </small>
                    </v-col>
                  </v-row>
                  <v-col cols="12" sm="6" md="6" v-if="getRuolo === 1">
                    <v-checkbox
                      v-model="editedItem.scrittura"
                      @change="switchPermessoScrittura($event)"
                      label="Permessi di scrittura"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" v-if="isRuoloLegale">
                    <v-checkbox
                      :disabled="editedItem.referenteProgramma"
                      v-model="editedItem.referenteCorso"
                      @change="toggleRuoloReferenteCorso($event)"
                      label="Ruolo Referente Corso"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" v-if="isRuoloLegale">
                    <v-checkbox
                      :disabled="editedItem.referenteCorso"
                      v-model="editedItem.referenteProgramma"
                      @change="toggleRuoloReferenteProgramma($event)"
                      label="Ruolo Referente per il Programma"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"> Annulla </v-btn>
              <v-btn color="blue darken-1" text @click="save"> Salva </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">
              Attenzione, sei sicuro di voler cancellare l'utente?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Annulla</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <!-- eslint-disable -->
    <template v-slot:item.abilitato="{ item }">
      <v-icon class="mr-2" v-if="item.abilitato"> mdi-check </v-icon>
    </template>

    <template v-slot:item.scrittura="{ item }" v-if="isRuoloIstituto">
      <v-icon class="mr-2" v-if="checkPermessoScrittura(item.permessi)">
        mdi-check
      </v-icon>
    </template>

    <template v-slot:item.referenteCorso="{ item }" v-if="isRuoloLegale">
      <v-icon class="mr-2" v-if="checkRefeCorso(item.ruoli)">
        mdi-check
      </v-icon>
    </template>

    <template v-slot:item.referenteProgramma="{ item }" v-if="isRuoloLegale">
      <v-icon class="mr-2" v-if="checkRefeProgramma(item.ruoli)">
        mdi-check
      </v-icon>
    </template>

    <template v-slot:item.actions="{ item }">
      <template v-if="!isRoleMinistero">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <template v-if="getRuolo !== 6">
          <v-icon
            small
            @click="deleteItem(item)"
            v-if="
              !checkPermessoGestioneUtenti(item.permessi) ||
              ruolo !== 'ROLE_LEGALE_RAPPRESENTANTE'
            "
          >
            mdi-delete
          </v-icon>
        </template>
      </template>
    </template>

    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize"> Reset </v-btn>
    </template>
    <!-- eslint-enable -->
  </v-data-table>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import UtentiRepository from "@/api/istituto/UtentiRepository";
import IstitutoRepository from "@/api/istituto/IstitutoRepository";

export default {
  name: "ListaUtenti",
  props: {
    ruolo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      bloccaInserimento: false,
      dialog: false,
      dialogDelete: false,
      utenti: [],
      loadingUtenti: true,
      editedIndex: -1,
      editedItem: {
        id: null,
        username: "",
        scrittura: false,
        referenteCorso: false,
        referenteProgramma: false,
        abilitato: true,
        anagrafica: {
          email: "",
          nome: "",
          cognome: "",
          codiceFiscale: "",
        },
        ruoli: [],
        permessi: [],
      },
      abilitazione: {
        idRuolo: null,
      },
    };
  },

  created() {
    this.initialize();
  },

  computed: {
    ...mapState(["idStrutture", "anno", "user", "scrivaniaViewMode"]),
    ...mapGetters([
      "isRoleMinistero",
      "isRoleLegale",
      "isRoleConsulenza",
      "isRoleReferenteProgramma",
    ]),
    headers() {
      const head = [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Username", value: "username" },
        { text: "Email", value: "anagrafica.email" },
        { text: "Nome", value: "anagrafica.nome" },
        { text: "Cognome", value: "anagrafica.cognome" },
        { text: "Abilitato", value: "abilitato" },
      ];

      if (this.isRuoloIstituto) {
        head.push({
          text: "Permessi di scrittura",
          value: "scrittura",
          align: "center",
          default: false,
        });
      }

      if (this.isRuoloLegale) {
        head.push({
          text: "Referente di corso",
          value: "referenteCorso",
          align: "center",
          default: false,
        });
        head.push({
          text: "Referente per il Programma",
          value: "referenteProgramma",
          align: "center",
          default: false,
        });
      }
      head.push({ text: "Azioni", value: "actions", sortable: false });

      return head;
    },
    formDialogTitle() {
      return this.editedIndex === -1 ? "Nuovo utente" : "Modifica utente";
    },
    tableTitle() {
      switch (this.ruolo) {
        case "ROLE_ISTITUTO":
          return "Supporto inserimento dati";
        case "ROLE_REFERENTE_CORSI":
          return "Referenti di Corso";
        case "ROLE_REFERENTE_PROGRAMMA":
          return "Referente per il Programma";
        case "ROLE_LEGALE_RAPPRESENTANTE":
          return "Legale Rappresentante";
        default:
          return "";
      }
    },
    getRuolo() {
      switch (this.ruolo) {
        case "ROLE_ISTITUTO":
          return 1;
        case "ROLE_REFERENTE_CORSI":
          return 5;
        case "ROLE_REFERENTE_PROGRAMMA":
          return 6;
        case "ROLE_LEGALE_RAPPRESENTANTE":
          return 7;
        default:
          return "";
      }
    },
    isRuoloIstituto() {
      return this.ruolo === "ROLE_ISTITUTO";
    },
    isRuoloLegale() {
      return this.ruolo === "ROLE_LEGALE_RAPPRESENTANTE";
    },
    isRuoloProgramma() {
      return this.ruolo === "ROLE_REFERENTE_PROGRAMMA";
    },
    defaultRuoli() {
      switch (this.ruolo) {
        case "ROLE_ISTITUTO":
          return [{ id: 1, descrizione: "ROLE_ISTITUTO" }];
        case "ROLE_REFERENTE_CORSI":
          return [{ id: 5, descrizione: "ROLE_REFERENTE_CORSI" }];
        case "ROLE_REFERENTE_PROGRAMMA":
          return [{ id: 6, descrizione: "ROLE_REFERENTE_PROGRAMMA" }];
        case "ROLE_LEGALE_RAPPRESENTANTE":
          return [{ id: 7, descrizione: "ROLE_LEGALE_RAPPRESENTANTE" }];
        default:
          return "";
      }
      /*return this.isRuoloIstituto
        ? [{ id: 1, descrizione: "ROLE_ISTITUTO" }]
        : [{ id: 5, descrizione: "ROLE_REFERENTE_CORSI" }];*/
    },
    defaultPermessi() {
      return this.isRuoloIstituto
        ? [{ id: 1, descrizione: "ROLE_LETTURA" }]
        : [
            { id: 1, descrizione: "ROLE_LETTURA" },
            { id: 2, descrizione: "ROLE_SCRITTURA" },
          ];
    },
    defaultItem() {
      return {
        id: null,
        username: "",
        scrittura: false,
        abilitato: true,
        anagrafica: {
          email: "",
          nome: "",
          cognome: "",
          codiceFiscale: "",
        },
        ruoli: this.defaultRuoli,
        permessi: this.defaultPermessi,
      };
    },
  },

  watch: {
    dialog(val) {
      if (this.editedIndex < 0 || this.editedIndex == null) {
        this.editedItem = Object.assign({}, this.defaultItem);
      }
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    idStrutture(newIdStrutture) {
      if (newIdStrutture !== null) {
        this.initialize();
      }
    },
    anno() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      if (this.idStrutture != null) {
        this.getUtenti();
        this.getAbilitaFirma();
      }
    },

    async getUtenti() {
      this.loadingUtenti = true;
      this.utenti = await UtentiRepository.getUtenti(
        this.idStrutture,
        this.ruolo
      );
      if (!this.isRuoloLegale) {
        await this.rimuoviReferentiIstituto();
      }
      this.loadingUtenti = false;
      if (this.getRuolo === 7) {
        //this.bloccaInserimento = this.utenti.length >= 1;
        this.bloccaInserimento = true;
      }
      if (this.getRuolo === 6) {
        this.bloccaInserimento = true;
        /*if (this.scrivaniaViewMode === 4) {
          this.bloccaInserimento = this.utenti.length >= 2;
        } else {
          this.bloccaInserimento = this.utenti.length >= 1;
        }*/
      }
    },

    async rimuoviReferentiIstituto() {
      const utentiBuoni = [];
      this.utenti.forEach((value) => {
        if (
          !this.checkRefeCorso(value.ruoli) &&
          !this.checkRefeProgramma(value.ruoli)
        ) {
          utentiBuoni.push(value);
        }
      });
      this.utenti = utentiBuoni;
    },

    generaUsername() {
      if (this.editedItem.id == null) {
        let nome =
          this.editedItem.anagrafica.nome.trim().toLowerCase().charAt(0) ?? "";
        let cognome = this.editedItem.anagrafica.cognome.trim().toLowerCase();
        this.editedItem.username = nome + "." + cognome.replaceAll(" ", "");
      }
    },

    editItem(item) {
      this.editedIndex = this.utenti.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (this.editedIndex === -1) {
        this.editedItem.abilitato = true;
      }
      this.editedItem.scrittura = this.editedItem.permessi.some(
        (p) => p.id === 2
      );
      this.editedItem.referenteCorso = this.editedItem.ruoli.some(
        (p) => p.id === 5
      );
      this.editedItem.referenteProgramma = this.editedItem.ruoli.some(
        (p) => p.id === 6
      );
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.utenti.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      await this.deleteUtente(this.editedItem);
      this.utenti.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.editedIndex > -1) {
        const editedItem = { ...this.editedItem };
        delete editedItem["dataScadenzaPassword"];
        delete editedItem["dataScadenzaPasswordFormatted"];

        const utente = await this.updateUtente(editedItem);
        if (!utente) {
          console.log("utente non aggiornato correttamente");
        }
        Object.assign(this.utenti[this.editedIndex], utente);
      } else {
        const utente = await this.creaUtente(this.editedItem);
        this.utenti.push(utente);
      }
      this.close();
    },

    async creaUtente(editedItem) {
      try {
        return await UtentiRepository.creaUtente(this.idStrutture, editedItem);
      } catch (e) {
        console.log(e);
        return null;
      }
    },

    async updateUtente(editedItem) {
      try {
        return await UtentiRepository.updateUtente(
          this.idStrutture,
          editedItem
        );
      } catch (e) {
        console.log(e);
        return null;
      }
    },

    async deleteUtente(editedItem) {
      try {
        await UtentiRepository.deleteUtente(this.idStrutture, editedItem.id);
      } catch (e) {
        console.log(e);
      }
    },

    switchPermessoScrittura(checked) {
      if (this.editedItem.permessi.some((p) => p.id === 2) && !checked) {
        const index = this.editedItem.permessi.findIndex((p) => p.id === 2);
        this.editedItem.permessi.splice(index, 1);
      }
      if (checked) {
        this.editedItem.permessi.push({ id: 2, descrizione: "ROLE_SCRITTURA" });
      }
    },

    toggleRuoloReferenteCorso(checked) {
      const idRuoloReferenteCorso = 5;
      if (
        this.editedItem.ruoli.some((p) => p.id === idRuoloReferenteCorso) &&
        !checked
      ) {
        const index = this.editedItem.ruoli.findIndex(
          (p) => p.id === idRuoloReferenteCorso
        );
        this.editedItem.ruoli.splice(index, 1);
      }
      if (checked) {
        this.editedItem.ruoli.push({
          id: 5,
          descrizione: "ROLE_REFERENTE_CORSI",
        });
      }
    },

    toggleRuoloReferenteProgramma(checked) {
      const idRuoloReferenteProgramma = 6;
      if (
        this.editedItem.ruoli.some((p) => p.id === idRuoloReferenteProgramma) &&
        !checked
      ) {
        const index = this.editedItem.ruoli.findIndex(
          (p) => p.id === idRuoloReferenteProgramma
        );
        this.editedItem.ruoli.splice(index, 1);
      }
      if (checked) {
        this.editedItem.ruoli.push({
          id: 6,
          descrizione: "ROLE_REFERENTE_PROGRAMMA",
        });
      }
    },

    checkPermessoScrittura(permessi) {
      return permessi.some((p) => p.id === 2);
    },

    checkPermessoGestioneUtenti(permessi) {
      return permessi.some((p) => p.id === 3);
    },

    checkRefeCorso(ruoli) {
      const isReferenteCorso = ruoli.some((r) => r.id === 5);
      const islegale = ruoli.some((r) => r.id === 7);
      return isReferenteCorso && islegale;
    },
    checkRefeProgramma(ruoli) {
      const isReferenteProgramma = ruoli.some((r) => r.id === 6);
      const islegale = ruoli.some((r) => r.id === 7);
      return isReferenteProgramma && islegale;
    },
    async updateAbilitaFirma() {
      this.abilitazione = await IstitutoRepository.updateAbilitaFirma(
        this.idStrutture,
        this.anno,
        this.abilitazione.idRuolo ?? 0
      );
      this.$emit("check-abilitato");
    },
    async getAbilitaFirma() {
      this.abilitazione = await IstitutoRepository.getAbilitaFirma(
        this.idStrutture,
        this.anno
      );
    },
  },
};
</script>

<style scoped></style>
