<template>
  <div class="home">
    <AlertErroriCf />
    <AlertComponent
      v-for="(testiAlert, index) in getTestoAlertRendiconto()"
      :key="index"
      :tipo-alert="'info'"
      :testo="testiAlert"
    />
    <AlertRichiesteRendiconti />
    <AlertMonitoraggi />
    <NewsComponent />
    <hr />
    <div class="pt-6 col-12">
      <div class="row">
        <div class="col-8">
          <h2>ORIENTAMENTO 2026</h2>
          <p class="pt-2">
            "Il Piano Nazionale di Ripresa e Resilienza (PNRR) definisce il
            programma di investimenti e di riforme che il governo italiano ha
            predisposto per fronteggiare la crisi prodotta dalla pandemia
            Covid19 e rimettere il Paese su un piano di crescita sostenibile e
            inclusiva, utilizzando le risorse messe a disposizione
            dall’Iniziativa europea Next Generation Eu (NGEU).
          </p>
          <p>
            Tra le iniziative proposte dal PNRR è previsto il potenziamento
            dell’offerta dei servizi di istruzione: dagli asili nido alle
            università che include, in particolare, l’investimento relativo
            all’Orientamento attivo nella transizione scuola-università. Le
            risorse destinate all’investimento ammontano a 250 milioni di euro
            da ripartire annualmente nel quinquennio 2022-2026. I criteri di
            assegnazione delle risorse dipenderanno da diversi parametri, in
            generale si baseranno sui criteri di assegnazione delle risorse sono
            definiti dal d.m. del 3 agosto 2022, n. 934, articolo 5 delle varie
            università.
          </p>
          <p>
            L'investimento mira a facilitare e incoraggiare il passaggio dalla
            scuola secondaria superiore all'università e, allo stesso tempo, ad
            affrontare gli abbandoni universitari negli anni successivi,
            contribuendo a porre le basi per il raggiungimento dell'obiettivo
            strategico di aumentare il numero dei laureati. L'investimento
            contribuisce alla qualificazione del sistema educativo attraverso un
            innalzamento degli indicatori di successo (frequenza scolastica,
            miglioramento dei livelli di apprendimento, numero di studenti
            ammessi all'anno accademico successivo, ecc.) e la mitigazione dei
            divari di genere, entrambi in termini di occupazione e
            partecipazione all'istruzione superiore in tutti i campi.
          </p>
          <p>
            La misura, implementata dal MUR, consiste in un programma di
            investimenti a favore degli studenti a partire dal terzo anno della
            scuola superiore, con un risultato atteso di aumento del tasso di
            transizione tra scuola e università. In particolare, prevede la
            formazione di 1 milione di studenti, attraverso corsi brevi erogati
            da docenti universitari e insegnanti scolastici che consentano agli
            studenti di comprendere meglio l’offerta dei percorsi didattici
            universitari e di colmare i gap presenti nelle competenze di base
            che sono richieste."
            <span class="font-italic"
              >Fonte: Piano Nazionale di Ripresa e Resilienza</span
            >
          </p>
        </div>
        <div class="col-4">
          <h4 class="pt-5">Documenti informativi</h4>
          <ul>
            <li>
              <a
                target="_blank"
                href="https://www.mur.gov.it/sites/default/files/2022-08/Decreto%20Ministeriale%20n.%20934%20del%2003-08-2022.pdf"
                >Decreto Ministeriale n. 934 del 03-08-2022</a
              >
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.mur.gov.it/sites/default/files/2022-09/Decreto%20Direttoriale%20n.%201452%20del%2022-09-2022.pdf"
                >Decreto Direttoriale n. 1452 del 22-09-2022</a
              >
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.mur.gov.it/sites/default/files/2022-09/Decreto%20Direttoriale%20n.%201452%20%20Allegato%201%20riparto%20risorse%20ripartizione%20Sud%20e%20Isole.pdf"
                >Decreto Direttoriale n. 1452 Allegato 1 riparto risorse
                ripartizione Sud e Isole</a
              >
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.mur.gov.it/sites/default/files/2022-09/Decreto%20Direttoriale%20n.%201452%20Allegato%202%20riparto%20risorse%20ripartizione%20Nord%20e%20Centro.pdf"
                >Decreto Direttoriale n. 1452 Allegato 2 riparto risorse
                ripartizione Nord e Centro</a
              >
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.mur.gov.it/sites/default/files/2022-09/Decreto%20Direttoriale%20n.%201452%20%20Allegato%203_modello%20piano%20di%20orientamento%20e%20schema%20monitoraggio.pdf"
                >Decreto Direttoriale n. 1452 Allegato 3_modello piano di
                orientamento e schema monitoraggio</a
              >
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.mur.gov.it/sites/default/files/2022-09/Decreto%20Direttoriale%20n.%201452%20%20Allegato%204_atto%20accettazione%20finanziamenti.pdf"
                >Decreto Direttoriale n. 1452 Allegato 4_atto accettazione
                finanziamenti</a
              >
            </li>
            <li>
              <a
                target="_blank"
                href="https://orientamento2026-backend.cineca.it/uploads/2022/istituto/Decreto Direttoriale n. 1452  Allegato 5_TEMPLATE CUP.pdf"
                >Decreto Direttoriale n. 1452 Allegato 5_TEMPLATE CUP</a
              >
            </li>
            <li>
              <a
                target="_blank"
                href="https://orientamento2026-backend.cineca.it/uploads/2022/ministero/Template_2205001_orientamento_all_universita_rev_21ottobre2022.pdf"
                >Decreto Direttoriale n. 1452 Allegato 5_TEMPLATE CUP
                (Aggiornato)</a
              >
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.mur.gov.it/sites/default/files/2022-09/Decreto%20Direttoriale%20n.%201452%20%20Allegato%206_schema%20Accordo%20Univ%20AFAM%20-%20Scuola.pdf"
                >Decreto Direttoriale n. 1452 Allegato 6_schema Accordo Univ
                AFAM - Scuola</a
              >
            </li>
            <li>
              <a
                target="_blank"
                href="https://orientamento2026-backend.cineca.it/uploads/2022/ministero/Nota_approvazione_target.04-10-2022.pdf"
                >Nota approvazione target 0011885 04-10-2022</a
              >
            </li>
            <li>
              <a
                target="_blank"
                href="https://orientamento2026-backend.cineca.it/uploads/2022/ministero/REGISTRO%20UFFICIALE(U).0003221.07-10-2022.pdf"
                >Adozione del D.M. 934/2021 3 del D.D.G. 1452/2022, relativi
                all'attuazione dell'investimento 1.6 – Avvio M4C1-24
                "Orientamento attivo scuola – università" rivolto all’ultimo
                triennio delle scuole secondarie di II grado.
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://orientamento2026-backend.cineca.it/uploads/2022/istituto/Allegato%204_atto%20accettazione%20finanziamenti.docx"
                >Allegato 4 atto accettazione finanziamenti.docx</a
              >
            </li>
            <li>
              <a
                target="_blank"
                href="https://orientamento2026-backend.cineca.it/uploads/2022/ministero/Decreto%20attribuzione%20delle%20risorse%2022_23(R).0001639.17-10-2022.pdf"
              >
                Decreto Direttoriale n. 1639 del 17-10-2022.pdf</a
              >
            </li>
            <li>
              <a
                target="_blank"
                href="https://orientamento2026-backend.cineca.it/uploads/2022/ministero/Tabella%201%20-%20attribuzione%20SUD%20e%20ISOLE.pdf"
                >Tabella 1 - attribuzione SUD e ISOLE</a
              >
            </li>
            <li>
              <a
                target="_blank"
                href="https://orientamento2026-backend.cineca.it/uploads/2022/ministero/Tabella%202%20-%20attribuzione%20CENTRO%20e%20NORD.pdf"
                >Tabella 2 - attribuzione CENTRO e NORD
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://orientamento2026-backend.cineca.it/uploads/2022/istituto/m_piAOODGFIS_REGISTRO_UFFICIALE_0015710.30-12-2022.pdf"
                >Domande Frequenti
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://orientamento2026-backend.cineca.it/uploads/2022/istituto/Schema per raccolta contatti uffici.xlsx"
                >Schema per raccolta contatti uffici
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://orientamento2026-backend.cineca.it/uploads/2022/ministero/Decreto_Direttoriale_n.2170_del_30-12-2022-1.pdf"
              >
                D.D. 2170/2022 di assegnazione definitiva dei fondi.
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewsComponent from "@/components/News";
import AlertErroriCf from "@/components/AlertErroriCf";
import AlertRichiesteRendiconti from "@/components/AlertRichiesteRendiconti";
import AlertComponent from "@/components/Common/AlertComponent";
import AlertMonitoraggi from "@/components/Monitoraggi/AlertMonitoraggi.vue";
//import AlertMonitoraggi from "@/components/AlertMonitoraggi";
export default {
  name: "HomeView",
  components: {
    AlertMonitoraggi,
    //AlertMonitoraggi,
    AlertComponent,
    AlertErroriCf,
    AlertRichiesteRendiconti,
    NewsComponent,
  },
  methods: {
    getTestoAlertRendiconto() {
      return [
        "Si avvisa che da oggi 27/10/23 è stato aggiornato l'elenco dei <b>codici meccanografici</b> degli Istituti scolastici con cui stipulare accordi.",
      ];
      /*return (
        "Al fine di consentire " +
        "il completamento dei rendiconti da parte di tutte le istituzioni interessate, " +
        "si fa presente che la relativa procedura resterà disponibile fino a venerdì 16 giugno, ore 17:00."
      );*/
    },
  },
};
</script>
