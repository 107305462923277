<template>
  <v-container>
    <BreadcrumbComponent :breadcrumbs="breadcrumbs" v-if="capofila" />
    <v-row class="mb-4">
      <v-col>
        <h1>Download verbale di esito</h1>
        <h4 class="grey--text">
          È in corso il download del verbale di esito del rendiconto id
          {{ idRendiconto }}
          - Anno Scolastico
          {{ this.anno }}/{{ this.anno + 1 }}
        </h4>
      </v-col>
    </v-row>

    <div v-if="loadingComponent()">
      <v-card class="ma-5 align-content-center" height="50%" elevation="5">
        <v-card-title>
          <b> Verbale Direzione Generale Controlli</b></v-card-title
        >
        <v-card-text class="align-items-center">
          <a
            v-if="verifica && verifica.fileVerificaRendiconto"
            @click="downloadFile(1)"
          >
            <v-icon>mdi-file-download-outline</v-icon> Scarica il file
            manualmente
          </a>
          <v-alert outlined type="info" v-else width="50%">
            <span class="text-center">
              Non è stato ancora firmato il verbale di verifica da parte della
              Direzione Generale Controlli.
            </span>
          </v-alert>
        </v-card-text>
      </v-card>
      <v-card
        class="ma-5 align-content-center align-center align-items-center"
        height="50%"
        elevation="5"
      >
        <v-card-title> <b> Verbale UDM</b></v-card-title>
        <v-card-text class="align-items-center">
          <a
            v-if="
              verificaSostanziale && verificaSostanziale.fileVerificaRendiconto
            "
            @click="downloadFile(2)"
          >
            <v-icon>mdi-file-download-outline</v-icon> Scarica il file
            manualmente
          </a>
          <v-alert outlined type="info" class="text-center" width="50%" v-else>
            <span class="text-center">
              Non è stato ancora firmato il verbale di esito da parte dell'Unità
              di Missione.
            </span></v-alert
          >
        </v-card-text>
      </v-card>
    </div>
    <v-progress-circular v-else color="blue" indeterminate />
    <v-row>
      <v-col>
        <div class="mt-4">
          <v-btn text class="blue--text" @click="$router.go(-1)">
            <v-icon>mdi-arrow-left-thin</v-icon>Torna a trasmissione rendiconto
            di progetto
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import RendicontiRepository from "@/api/istituto/RendicontiRepository";
import IstitutoRepository from "@/api/istituto/IstitutoRepository";

export default {
  name: "downloadDettaglioVerbali",
  components: { BreadcrumbComponent },
  data: () => ({
    loadingDettaglio: true,
    verifica: null,
    verificaSostanziale: null,
    rendiconto: null,
  }),
  props: {
    capofila: {
      type: String,
      required: true,
    },
    idRendiconto: {
      type: Number,
      required: true,
    },
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapState(["idStrutture", "anno", "user"]),
    ...mapGetters([
      "isReadOnly",
      "isRoleReferenteCorsi",
      "isRoleConsulenza",
      "isRoleLegale",
    ]),
    loading: function () {
      return this.loadingDettaglio;
    },
    breadcrumbs: function () {
      if (this.capofila) {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania/${this.capofila}`,
            exact: true,
            link: true,
          },
          {
            text: "Creazione e trasmissione rendiconto di progetto",
            disabled: false,
            to: `/scrivania/${this.capofila}/rendiconti`,
            exact: true,
            link: true,
          },
          {
            text: "Dettaglio verbale esito",
            disabled: true,
            to: `/scrivania/${this.capofila}/rendiconti/dettaglioIntegrazioniModifiche`,
            exact: true,
            link: true,
          },
        ];
      }
      return [];
    },
  },
  watch: {
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      if (this.idStrutture != null && this.anno != null) {
        this.getRendiconto(this.idStrutture, this.idRendiconto);
      }
    },
    async getRendiconto(idStrutture, idRendiconto) {
      this.loadingDettaglio = true;
      await RendicontiRepository.getSingoloRendiconto(
        idStrutture,
        idRendiconto
      ).then((data) => {
        this.rendiconto = data;
        this.verifica = this.rendiconto.verificaRendiconto;
        this.verificaSostanziale =
          this.rendiconto.verificaRendicontoUdm ?? null;
      });
      // verifica dg controlli
      // await this.getFileEistoVerifica(this.verifica);
      this.loadingDettaglio = false;
    },
    loadingComponent() {
      return this.rendiconto !== null;
    },

    async downloadFile(tipoVerifica) {
      let fileDaScaricare = null;
      switch (tipoVerifica) {
        case 1:
          fileDaScaricare = this.verifica.fileVerificaRendiconto;
          break;

        case 2:
          fileDaScaricare = this.verificaSostanziale.fileVerificaRendiconto;
          break;
      }
      await IstitutoRepository.downloadFile(this.idStrutture, fileDaScaricare);
    },
    /* async getFileEistoVerifica(verifica) {
      console.log(verifica);
      await RendicontiRepository.getFileEistoVerifica(
        this.idStrutture,
        verifica.id,
        verifica.fileVerificaRendiconto.nomeOriginale
      );
    },*/
  },
};
</script>

<style scoped></style>
