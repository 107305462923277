<template>
  <div class="container">
    <v-main>
      <BreadcrumbComponent :breadcrumbs="breadcrumbs" />
    </v-main>
    <v-progress-circular v-if="!loading" />
    <v-card-title> <h1>Riepilogo corsi-questionari</h1> </v-card-title>
    <v-card elevation="6">
      <RiepilogoConteggiQuestionari
        v-if="loadingComponent"
        :count-questionari-inviati="conteggi.questionariInviati"
        :count-studenti="conteggi.studentiTotali"
        :count-link-generati="conteggi.linkGenerati"
      />
      <v-card-actions>
        <v-row>
          <v-col class="flex-column-reverse">
            <v-btn
              v-if="!downloadExcelInCorso"
              color="primary"
              outlined
              @click="downloadExcel(idStrutture, null, true)"
            >
              Download excel di tutte le risposte</v-btn
            >
            <v-progress-circular v-else indeterminate color="blue" />
          </v-col>
        </v-row>
      </v-card-actions>
      <v-main>
        <v-data-table
          :items="corsiQuestionari"
          :headers="headersTable"
          item-key="corsoOrientamento.id"
          no-data-text="Nessun corso creato"
        >
          <!--eslint-disable -->
          <template v-slot:item.linkQuestionario="{ item }">
            <span v-if="item.idLink">
              <v-btn class="btn-excel"
                     color="primary"
                     outlined
                     v-if="isVisualizzabileBottoneExcel(item.dataScadenzaQuestionario)"
                     @click="downloadExcel(idStrutture, item.corsoOrientamento.id)"
              >
                Download excel
              </v-btn>
              <span v-else>
                Link questionario creato ma non ancora scaduto.
              </span>
            </span>
            <span v-else>
              Questionario non generato
            </span>
          </template>
          <template v-slot:item.istituto="{ item }">
             <span v-if="item.corsoOrientamento.accordi.length"
                   v-for="(accordiScuola, index) in item.corsoOrientamento.accordi">
               {{ accordiScuola.istituto.denominazioneScuola }} <br />
             </span>
          </template>
          <template v-slot:item.statoCorso="{ item }">
             <span v-html="getStatoCorso(
                 item.corsoOrientamento.dataInizio,
                 item.corsoOrientamento.dataFine,
                 item.corsoOrientamento.dataTermineCorso
                 )" />
          </template>
          <!-- eslint-enable -->
        </v-data-table>
      </v-main>
    </v-card>
  </div>
</template>
<script>
import { mapState } from "vuex";
import QuestionariRepository from "@/api/istituto/QuestionariRepository";
import RiepilogoConteggiQuestionari from "@/components/Questionari/RiepilogoConteggiQuestionari.vue";
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent.vue";

export default {
  name: "CorsiQuestionari",
  components: { BreadcrumbComponent, RiepilogoConteggiQuestionari },
  props: {
    capofila: {
      type: String,
      required: true,
    },
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapState(["anno", "idStrutture"]),
    breadcrumbs() {
      return [
        {
          text: "Scrivania",
          disabled: false,
          to: `/scrivania/${this.capofila}`,
          exact: true,
          link: true,
        },
        {
          text: "Censimento, erogazione e validazione corsi",
          disabled: false,
          to: `/scrivania/${this.capofila}/corsi`,
          exact: true,
          link: true,
        },
        {
          text: "Riepilogo questionari",
          disabled: true,
          to: `/scrivania/${this.capofila}{/corsi-questionari`,
          exact: true,
          link: true,
        },
      ];
    },
  },
  watch: {
    anno() {
      this.initialize();
    },
    idStrutture() {
      this.initialize();
    },
  },
  data: () => ({
    loading: false,
    loadingComponent: false,
    corsiQuestionari: [],
    downloadExcelInCorso: false,
    conteggi: {
      linkGenerati: 0,
      studentiTotali: 0,
      questionariInviati: 0,
    },
    headersTable: [
      {
        align: "start",
        text: "ID CORSO",
        value: "corsoOrientamento.id",
      },
      {
        text: "Istituto scolastico",
        value: "istituto",
        sortable: false,
      },
      {
        text: "Numero studenti censiti",
        value: "corsoOrientamento.numeroStudenti",
      },
      {
        text: "Numero studenti con questionario compilato",
        value: "countQuestionari",
      },
      {
        text: "Stato corso",
        value: "statoCorso",
        sortable: false,
      },
      {
        text: "Link questionario",
        value: "linkQuestionario",
        sortable: false,
      },
    ],
  }),
  methods: {
    initialize() {
      this.getCorsiLinkQuestionari();
      this.loading = true;
    },
    async getCorsiLinkQuestionari() {
      this.corsiQuestionari =
        await QuestionariRepository.getCorsiLinkQuestionari(
          this.idStrutture,
          this.anno,
          this.capofila
        );
      if (this.corsiQuestionari) {
        this.getRiepilogoConteggiQuestionari();
      }
    },
    isVisualizzabileBottoneExcel(dataScadenzaLink) {
      return new Date(dataScadenzaLink) < new Date();
    },
    async downloadExcel(idStrutture, idCorso) {
      this.downloadExcelInCorso = true;
      await QuestionariRepository.downloadRisposteQuestionariIstituzione(
        idStrutture,
        this.anno,
        idCorso
      );
      this.downloadExcelInCorso = false;
    },
    getRiepilogoConteggiQuestionari() {
      let countLink = 0;
      let countStudenti = 0;
      let countQuestionari = 0;

      this.corsiQuestionari.forEach((element) => {
        if (element.idLink) {
          countStudenti += element.numeroStudentiCorso;
          countLink += 1;
        }
        countQuestionari += element.countQuestionari;
      });

      this.conteggi.questionariInviati = countQuestionari;
      this.conteggi.studentiTotali = countStudenti;
      this.conteggi.linkGenerati = countLink;

      this.loadingComponent = true;
    },
    getStatoCorso(dataInizio, dataFine, terminato) {
      dataInizio = new Date(dataInizio);
      dataFine = new Date(dataFine);
      const dateOdierna = new Date();
      if (terminato) {
        return "TERMINATO";
      }
      if (dateOdierna < dataInizio) {
        return "DA EROGARE";
      } else if (dateOdierna >= dataInizio && dateOdierna <= dataFine) {
        return "IN EROGAZIONE";
      } else {
        return "EROGATO";
      }
    },
  },
};
</script>
<style scoped>
.btn-excel {
  max-height: 50%;
  max-width: 80%;
}
</style>
