<template>
  <div class="container">
    <div class="d-flex justify-center mb-12">
      <v-sheet
        v-for="(cardData, index) in cardContent"
        :key="index"
        class="pa-4"
        outlined
        tile
        style="margin-right: 1%"
      >
        <v-card-title style="text-align: left">
          <v-icon v-if="cardData.icon"> {{ cardData.icon }}</v-icon>
          <strong> {{ cardData.title }}</strong>
        </v-card-title>
        <v-card-subtitle> {{ cardData.subtitle }} </v-card-subtitle>
        <v-card-text>
          <h3 style="text-align: center" v-html="cardData.count" />
        </v-card-text>
      </v-sheet>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "RiepilogoConteggiQuestionari",
  components: {},
  props: {
    countLinkGenerati: {
      type: Number,
      required: true,
      default: 0,
    },
    countStudenti: {
      type: Number,
      required: true,
      default: 0,
    },
    countQuestionariInviati: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  computed: {
    ...mapState(["idStrutture", "anno"]),
  },
  watch: {
    anno() {
      this.initialize();
    },
    idStrutture() {
      this.initialize();
    },
  },
  data: () => ({
    loadingComponent: false,
    cardContent: [
      {
        title: "Totale questionari creati",
        subtitle:
          "Il numero totale dei corsi per cui è stato generato il link del questionario",
        count: 0,
        icon: "mdi-link",
      },
      {
        title: "Totale Studenti",
        subtitle: "Totale studenti a cui è stato somministrato il questionario",
        count: 0,
        icon: "mdi-account-outline",
      },
      {
        title: "Totale Studenti",
        subtitle: "Studenti che hanno compilato il questionario",
        count: 0,
        icon: "mdi-account-plus-outline",
      },
    ],
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.countLinkGenerati) {
        this.cardContent[0].count = this.countLinkGenerati;
      }

      if (this.countStudenti) {
        this.cardContent[1].count = this.countStudenti;
      }

      if (this.countQuestionariInviati) {
        this.cardContent[2].count = this.countQuestionariInviati;
      }
    },
  },
};
</script>
