import Repository from "../Repository";

export default {
  async getNews(idStrutture) {
    let url = `api/istituto/${idStrutture}/news`;
    return (await Repository.get(url)).data;
  },
  async getPrattMinistero(idStrutture) {
    let url = `api/istituto/${idStrutture}/pratt-ministero`;
    return (await Repository.get(url)).data;
  },
  async getFinestraCompilazione(idStrutture, anno, slug) {
    let url = `api/istituto/${idStrutture}/anno/${anno}/finestra-compilazione/${slug}`;
    return (await Repository.get(url)).data;
  },
  async downloadFile(idStrutture, orientamentoFile) {
    let url = `api/istituto/${idStrutture}/download-file/${orientamentoFile.id}`;
    const res = await Repository.get(url, {
      responseType: "blob",
    });
    if (res.status === 200) {
      const file = new Blob([res.data], {
        type: res.data.type,
      });
      // const fileName =
      //   res.headers["content-disposition"].split("filename=");
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download = orientamentoFile.nomeOriginale;
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
  async isPartecipanteProgrammaOrientamento(idStrutture, anno) {
    const url = `api/istituto/${idStrutture}/isPartecipanteProgrammaOrientamento/anno/${anno}`;
    return (await Repository.get(url)).data;
  },
  async isCapofila(idStrutture, anno) {
    const url = `api/istituto/${idStrutture}/isCapofila/anno/${anno}`;
    return (await Repository.get(url)).data;
  },
  async getAssegnazioneFinanziariaDefinitiva(idStrutture, anno) {
    let url = `api/istituto/${idStrutture}/assegnazione-finanziaria-definitiva/${anno}`;
    return (await Repository.get(url)).data;
  },
  async getAssegnazioniPeriodi(idStrutture, anno) {
    let url = `api/istituto/${idStrutture}/assegnazioni-periodi/anno/${anno}`;
    return (await Repository.get(url)).data;
  },
  async scrivaniaViewMode(idStrutture, anno) {
    const url = `api/istituto/${idStrutture}/scrivania-view-mode/anno/${anno}`;
    return (await Repository.get(url)).data;
  },
  async getSingolaStruttura(idStrutture) {
    let url = `api/istituto/${idStrutture}`;
    return (await Repository.get(url)).data;
  },
  async getStudentiConvalidati(idStrutture, anno) {
    let url = `api/istituto/${idStrutture}/studenti-convalidati/${anno}`;
    return (await Repository.get(url)).data;
  },
  async updateAbilitaFirma(idStrutture, anno, idRuolo) {
    let url = `api/istituto/${idStrutture}/${anno}/${idRuolo}/abilita-firma-attestati`;
    return (await Repository.put(url)).data;
  },
  async getAbilitaFirma(idStrutture, anno) {
    let url = `api/istituto/${idStrutture}/${anno}/utenti-abilitati`;
    return (await Repository.get(url)).data;
  },
  async getCertificatiErrati(idStrutture, anno) {
    let url = `api/istituto/${idStrutture}/certificati-errati/${anno}`;
    return (await Repository.get(url)).data;
  },
  async getStruttureUtente() {
    let url = `api/generici/strutture`;
    return (await Repository.get(url)).data;
  },
  async getDatiSoggettoAttuatore(idStrutture) {
    const url = `api/istituto/${idStrutture}/dati-soggetto-attuatore`;
    return (await Repository.get(url)).data;
  },
  async getDatiBancariStruttura(idStrutture, anno) {
    const url = `api/istituto/${idStrutture}/richieste/conti-iban-tesoreria/anno/${anno}`;
    return (await Repository.get(url)).data;
  },

  // async getNumeroStudentiConCertificato(
  //   idStrutture,
  //   anno,
  //   capofila,
  //   idRendiconto
  // ) {
  //   // se rendiconto è settato, il conteggio non includerà il rendiconto specificato
  //   // viceversa verranno ottenuti gli studenti con certificato associati a tutti i corsi rendicontati
  //   const url =
  //     idRendiconto != null
  //       ? `api/istituto/${idStrutture}/rendiconti/anno/${anno}/${capofila}/studenti-con-certificato/conteggio?rendiconto=${idRendiconto}`
  //       : `api/istituto/${idStrutture}/rendiconti/anno/${anno}/${capofila}/studenti-con-certificato/conteggio`;
  //   return (await Repository.get(url)).data;
  // },
  async getUltimoLegaleRappresentanteInseritoAttivo(idStrutture) {
    const url = `api/istituto/${idStrutture}/legale-rappresentante/ultimo-attivo`;
    return (await Repository.get(url)).data;
  },
  async getAccordiStudentiCensiti(idStrutture, anno, idCorso) {
    const url = `api/istituto/${idStrutture}/${anno}/${idCorso}/accordi-studenti-censiti`;
    return (await Repository.get(url)).data;
  },
  async getIsStatale(idStrutture) {
    const url = `api/generici/${idStrutture}/tipo-istituzione`;
    return (await Repository.get(url)).data;
  },
  async getTestoModificaReferenteProgramma(idStrutture, anno) {
    const url = `api/generici/${idStrutture}/anno/${anno}/testi-modifiche-rp`;
    return (await Repository.get(url)).data;
  },
};
