<template>
  <v-dialog v-model="modelDialog" size="xl">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-on="on"
        v-bind="attrs"
        color="primary"
        text
        @click="openDialog()"
      >
        <v-icon class="mx-2"> mdi-loupe </v-icon>
        Seleziona docente/i
      </v-btn>
    </template>

    <v-card elevation="4" v-if="modelDialog">
      <v-card-title>
        Seleziona docenti da associare al corso {{ idCorso }}</v-card-title
      >
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="itemSearched"
              append-icon="mdi-magnify"
              label="Ricerca per nome, cognome, codice fiscale"
              single-line
              class="w-50"
            />
          </v-col>
          <v-col cols="6"></v-col>
        </v-row>
        <v-alert type="info">
          Attenzione: si ricorda che è necessario selezionare il ruolo e il
          codice SSD dei docenti che si desidera aggiungere.
        </v-alert>
        <v-form
          v-if="loadingForm()"
          v-model="isFormValid"
          ref="docentiSelezionabili"
          lazy-validation
        >
          <v-data-table
            :headers="headerTabellaDocenti"
            :items="docentiSelezionabili"
            item-key="cf"
            class="elevation-1"
            :search="itemSearched"
            :single-select="false"
          >
            <!-- eslint-disable -->
          <template v-slot:header.selected="{ header }">
            <v-checkbox
              color="blue"
              v-model="checkboxDocenti"
              @input="setPresenzeDocenti"
              @change="setPresenzeDocenti"
            />
          </template>
          <template v-slot:item.selected="{ item }">
            <v-checkbox
              v-model="item.selected"
              color="blue"
            ></v-checkbox>
          </template>
          <template v-slot:item.ruolo="{ item, index}">
            <v-autocomplete
                :id="'item-ruolo'+index"
                :items="listaRuoli"
                item-text="descrizione"
                item-value="id"
                :label="item.selected ? 'Ruolo *' : 'Ruolo'"
                v-model="item.ruolo"
                class=""
            ></v-autocomplete>
          </template>
          <template v-slot:item.ssdSad="{ item, index}">
            <v-autocomplete
              :items="listaSsd"
              :id="'item-ssd'+index"
              item-text="text"
              item-value="value"
              :label="item.selected ? 'SSD *' : 'SSD'"
              v-model="item.ssd"
            />
          </template>
          <!--eslint-enable-->
          </v-data-table>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="6" class="flex-row-reverse">
            <v-btn color="warning" outlined @click="closeDialog(false)">
              Chiudi finestra di dialogo</v-btn
            >
          </v-col>
          <v-col cols="6">
            <v-btn
              color="primary"
              outlined
              :disabled="!isFormValid"
              @click="dialogConfermaOperazione = true"
            >
              Salva modifiche</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <dialog-conferma
      :dialog.sync="dialogConfermaOperazione"
      @callback="aggiungiDocentiCorso()"
      @close-dialog="dialogConfermaOperazione = false"
    >
    </dialog-conferma>
    <v-snackbar :color="snackbar.color" :value="snackbar.value">
      <span v-html="snackbar.text" />
    </v-snackbar>
  </v-dialog>
</template>
<script>
import DialogConferma from "@/components/DialogConferma.vue";
import CorsiRepository from "@/api/istituto/CorsiRepository";
import { mapState } from "vuex";

export default {
  name: "dialogSelezionaDocentiCorso",
  components: { DialogConferma },
  props: {
    idCorso: {
      type: Number,
      required: true,
    },
    listaSsd: {
      type: Array,
      required: true,
    },
    listaRuoli: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    loadingDialog: false,
    modelDialog: false,
    allRuoli: [],
    allSsd: [],
    headerTabellaDocenti: [
      {
        text: "Seleziona",
        value: "selected",
        filterable: false,
        sortable: false,
      },
      { text: "Nome", value: "nome", sortable: false },
      { text: "Cognome", value: "cognome", sortable: false },
      { text: "Codice Fiscale", value: "cf", sortable: false },
      { text: "Ruolo", value: "ruolo", filterable: false, sortable: false },
      { text: "SsdSad", value: "ssdSad", filterable: false, sortable: false },
    ],
    docentiSelezionabili: [],
    dialogConfermaOperazione: false,
    isFormValid: false,
    itemSearched: "",
    checkboxDocenti: false,
    snackbar: {
      text: "",
      value: false,
      color: "",
    },
    allDocentiInseritiByIstituzione: [],
  }),
  created() {
    this.initialize();
  },
  computed: {
    ...mapState(["idStrutture", "anno", "user"]),
  },
  watch: {
    idStrutture() {
      this.$destroy();
    },
    anno() {
      this.$destroy();
    },
  },
  methods: {
    initialize() {
      if (!this.readOnly) {
        this.getAllDocentiInseritiByIstituzione();
      }
    },
    async getAllDocentiInseritiByIstituzione() {
      await CorsiRepository.getAllDocentiInseritiByIstituzione(
        this.idStrutture,
        this.idCorso
      ).then((data) => {
        this.allDocentiInseritiByIstituzione = data;
        this.setupFormDocentiSelezionabili();
      });
    },
    openDialog() {
      setTimeout(() => {
        this.modelDialog = true;
      }, 1000);
    },
    loadingForm() {
      return this.docentiSelezionabili.length > 0;
    },
    setupFormDocentiSelezionabili() {
      this.docentiSelezionabili = this.allDocentiInseritiByIstituzione.map(
        (element) => {
          element.ruolo = null;
          element.ssd = null;
          element.selected = false;

          return element;
        }
      );
    },
    setPresenzeDocenti(value) {
      this.docentiSelezionabili = this.docentiSelezionabili.map((element) => {
        element.selected = value;
        return element;
      });
    },
    checkValidation(docentiSelezionati) {
      let messageErrore = "";
      docentiSelezionati.forEach((element) => {
        if (!element.ruolo) {
          messageErrore +=
            "Per il docente con codice fiscale <b> " +
            element.cf +
            " </b> non è stato selezionato il ruolo. <br />";
        }

        // gestione ssd mancante quando il ruolo associato al docente non è esperto di orientamento
        if (!element.ssd) {
          if (element.ruolo && parseInt(element.ruolo) !== 4) {
            messageErrore +=
              "Per il docente con codice fiscale<b> " +
              element.cf +
              " </b>non è stato selezionato l'ssd/sad. Il valore è obbligatorio se il ruolo assegnato al docente è diverso da 'Esperto di orientamento'. <br/>";
          }
        }
      });

      return messageErrore;
    },
    closeDialog(postOperation) {
      this.modelDialog = false;
      if (postOperation) {
        // ho salvato i dati, elimino eventuali caricamenti sul db
        this.allDocentiInseritiByIstituzione = [];
        this.docentiSelezionabili = [];
        this.getAllDocentiInseritiByIstituzione();
        // vado ad aggiornare i docenti inseriti nel component padre ModificaCensimentoCorsi
        this.$emit("manageCloseDialog", postOperation);
      }
    },
    aggiungiDocentiCorso() {
      let docentiScelti = this.docentiSelezionabili.filter((element) => {
        return element.selected;
      });
      // verifico se ruolo e ssd per ogni docente sono stati inseriti

      let messageError = "";
      if (docentiScelti.length === 0) {
        messageError = "Errore! Non è stato selezionato alcun docente.";
        this.setSnackbar(messageError, "red accent-2");
      } else {
        messageError = this.checkValidation(docentiScelti);
        messageError.length > 0
          ? this.setSnackbar(messageError, "red accent-2")
          : this.salvaInformazioni(docentiScelti);
      }
    },

    setSnackbar(text, color) {
      this.snackbar.text = text;
      this.snackbar.color = color;
      this.snackbar.value = true;
      setTimeout(() => {
        this.snackbar.value = false;
      }, 5000);
    },
    async salvaInformazioni(docentiScelti) {
      docentiScelti = docentiScelti.map((element) => {
        delete element.selected;
        return element;
      });

      const formBody = new FormData();
      formBody.append("docentiSelezionati", JSON.stringify(docentiScelti));

      await CorsiRepository.aggiungiDocentiDaSelezione(
        this.idStrutture,
        this.idCorso,
        formBody
      ).then((data) => {
        if (data) {
          this.setSnackbar(data, "success");
          this.resetFormDocenti();
          setTimeout(() => {
            this.closeDialog(true);
          }, 2000);
        }
      });
    },
    resetFormDocenti() {
      // elimino i docenti selezionati
      this.docentiSelezionabili = this.docentiSelezionabili.filter(
        (element) => {
          return !element.selected;
        }
      );
    },
  },
};
</script>
