<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-row>
          <v-col cols="12" class="d-flex flex-row-reverse">
            <v-btn text class="blue--text" v-bind="attrs" v-on="on"
              ><v-icon class="mx-2"> mdi-loupe </v-icon>
              Aggiungi docente
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <v-card>
        <v-card-title class="h1 justify-center">Aggiungi docente </v-card-title>

        <v-card-text v-if="loadingContentDialog">
          <v-container
            ><div class="my-4">
              <small> I campi contrassegnati con * sono obbligatori </small>
            </div>
            <v-form ref="form">
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Nome *"
                    v-model="docente.nome"
                    :rules="validazioneCampoNecessario()"
                    @input="validateField"
                    @change="validateField"
                    @keyup="uppercasePrimaLetteraNome()"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Cognome *"
                    v-model="docente.cognome"
                    :rules="validazioneCampoNecessario()"
                    @input="validateField"
                    @change="validateField"
                    @keyup="uppercasePrimaLetteraCognome()"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Codice Fiscale *"
                    v-model="docente.cf"
                    :rules="validazioneCF()"
                    @input="validateField"
                    @change="validateField"
                    @keyup="uppercase()"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    v-model="docente.ruolo"
                    :items="ruoliDocente"
                    item-text="descrizione"
                    item-value="id"
                    label="Ruolo *"
                    :rules="validazioneCampoNecessario()"
                    required
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                    v-model="docente.ssdSad"
                    :items="listaSsdSad"
                    item-text="text"
                    item-value="value"
                    label="SSD/SDA *"
                    :rules="validazioneCampoSsdSad(docente.ruolo)"
                    required
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn outlined color="blue darken-1" text @click="dialog = false">
            Annulla
          </v-btn>
          <v-btn class="primary" text @click="dialogSalva = true">
            Inserisci docente
          </v-btn>
        </v-card-actions>
        <dialog-conferma
          @callback="createDocenteCorso"
          :dialog.sync="dialogSalva"
          @close-dialog="dialogSalva = false"
        />

        <v-snackbar
          v-model="snackbar"
          :timeout="2000"
          shaped
          :color="snackbarColor"
        >
          {{ snackbarText }}

          <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import DialogConferma from "@/components/DialogConferma";
import CorsiRepository from "@/api/istituto/CorsiRepository";
export default {
  name: "DialogAggiungiDocente",
  components: {
    DialogConferma,
  },
  props: {
    idCorso: {
      type: Number,
      required: true,
    },
    listaSsd: {
      type: Array,
      required: true,
    },
    listaRuoli: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    dialogSalva: null,
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    validate: false,
    listaSsdSad: [],
    ruoliDocente: [],
    docente: {
      nome: null,
      cognome: null,
      ruolo: null,
      cf: null,
      genere: 0,
      classe: 0,
      ssdSad: null,
    },
    docenteDefault: {
      nome: null,
      cognome: null,
      ruolo: null,
      cf: null,
      genere: 0,
      classe: 0,
      ssdSad: null,
    },
    loadingContentDialog: false,
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.listaSsd.length && this.listaRuoli.length) {
        this.listaSsdSad = this.listaSsd;
        this.ruoliDocente = this.listaRuoli;
        this.loadingContentDialog = true;
      } else {
        // contenuto dialog da mostrare no da mostarre = no
        this.loadingContentDialog = false;
      }
      console.log(this.loadingContentDialog);
    },
    uppercase() {
      this.docente.cf = this.docente.cf.toUpperCase();
    },
    uppercasePrimaLetteraNome() {
      this.docente.nome =
        this.docente.nome.charAt(0).toUpperCase() + this.docente.nome.slice(1);
      //sostituito a causa di problemi con nomi e cognomi dei docenti
      //this.docente.nome[0].toUpperCase() + this.docente.nome.slice(1);
    },
    uppercasePrimaLetteraCognome() {
      this.docente.cognome =
        this.docente.cognome.charAt(0).toUpperCase() +
        this.docente.cognome.slice(1);
    },
    validateField() {
      this.validate = this.$refs.form.validate();
    },
    validazioneCF() {
      return [
        (v) =>
          (!!v && v.length === 16) ||
          "Il campo è obbligatorio e il formato del codice fiscale deve essere valido",
      ];
    },
    validazioneCampoSsdSad(ruoloDocente) {
      return [
        (v) =>
          !!v ||
          ruoloDocente === 4 ||
          "Il campo è obbligatorio ad esclusione degli esperti di orientamento",
      ];
    },
    validazioneCampoNecessario() {
      return [(v) => !!v || "Campo richiesto"];
    },
    async createDocenteCorso() {
      this.validateField();
      if (this.validate) {
        let docente = { ...this.docente };
        let idStrutture = this.$store.state.idStrutture;

        // post file to server
        const formData = new FormData();
        formData.append("docente", JSON.stringify(docente));

        try {
          this.nuovoDocente = await CorsiRepository.createDocente(
            idStrutture,
            formData
          );
          await CorsiRepository.createDocenteCorso(
            idStrutture,
            this.nuovoDocente.id,
            this.idCorso,
            this.docente.ruolo,
            formData
          );
          this.$emit("docente-aggiunto");
          this.docente = { ...this.docenteDefault };
          this.snackbarText = "Docente inserito con successo";

          this.snackbarColor = "success";
          this.validate = true;
          this.dialog = false;
        } catch (e) {
          console.log(e);
          this.snackbarText =
            "Problema durante il salvataggio, contattare il supporto se il problema persiste";
          this.snackbarColor = "red accent-2";
        }
        this.snackbar = true;
      }
    },
  },
};
</script>
