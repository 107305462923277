<template>
  <div>
    <v-dialog v-if="pdfDaCaricare" v-model="dialog">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary lighten-2" dark v-bind="attrs" v-on="on">
          Carica accordo
        </v-btn>
      </template>

      <v-card v-if="loadingComponent">
        <v-card-title> PDF Accordo scuola </v-card-title>
        <v-card-text>
          <v-form ref="formPDF" v-model="valid" lazy-validation>
            <v-row>
              <v-col>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-on="on"
                      v-bind="attrs"
                      v-model="formPDF.dataFirmaAccordo"
                      type="date"
                      label="Data firma accordo"
                      prepend-icon="mdi-calendar"
                      :rules="getValidazioneDataFirmaAccordo()"
                      @change="getValidazioneDataFirmaAccordo()"
                    ></v-text-field>
                  </template>
                  <span>
                    Inserire la data di sottoscrizione dell'accordo
                    Scuola-Università
                  </span>
                </v-tooltip>
              </v-col>
              <v-col>
                <v-file-input
                  accept="application/pdf"
                  :label="getPlaceholderInputFile(formPDF.pdfAccordo)"
                  v-model="formPDF.pdfAccordo"
                  :rules="[(v) => !!v || 'Il campo è obbligatorio']"
                  :disabled="false"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <v-btn
                :disabled="!valid"
                color="primary"
                @click="aggiornaAccordo()"
              >
                Carica PDF
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn color="warning" @click="dialog = false"> Chiudi</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-alert type="info" v-else>
      E' possibile procedere al caricameno del pdf una volta aver salvato i dati
      dell'accordo.
    </v-alert>
  </div>
  <!--<v-dialog>
    <v-card v-if="loadingComponent">
      <v-card-title> PDF Accordo scuola </v-card-title>
      <v-card-text>
        <v-row>
          <v-col></v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>-->
</template>

<script>
import ScuoleAccordoRepository from "@/api/istituto/ScuoleAccordoRepository";

export default {
  name: "modal-caricamento-accordo",
  components: {},
  props: {
    accordo: {
      type: Object,
      required: true,
    },
    pdfDaCaricare: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    loadingComponent: false,
    dialog: false,
    formPDF: {
      pdfAccordo: null,
      dataFirmaAccordo: null,
    },
    istitutoAccordo: null,
    valid: true,
  }),
  mounted() {
    if (this.accordo) {
      this.initialize();
    }
  },
  methods: {
    initialize() {
      this.istitutoAccordo = this.accordo.istituto.id;
      this.formPDF.pdfAccordo = this.accordo.fileAccordo ?? null;
      this.formPDF.dataFirmaAccordo = this.accordo.dataFirmaAccordo ?? null;
      this.loadingComponent = true;
    },
    getPlaceholderInputFile(item) {
      if (item) {
        return item.nomeOriginale;
      } else {
        return "File PDF Accordo-Scuola";
      }
    },
    getValidazioneDataFirmaAccordo() {
      let data = new Date(this.formPDF.dataFirmaAccordo);
      return [
        (v) => !!v || "Il campo deve essere valorizzato",
        () => {
          // la data non deve essere antecedende al 1 di settembre 2024;ù
          let minDate = new Date("2024-01-01");
          let maxDate = new Date("2026-04-30");
          return data < minDate || data > maxDate
            ? "La data non deve essere antecedente al 1 gennaio 2024 e posteriore al 30 aprile 2026"
            : true;
        },
      ];
    },
    async aggiornaAccordo() {
      if (this.$refs.formPDF.validate()) {
        await ScuoleAccordoRepository.modificaAccordo(
          this.accordo.struttura,
          this.accordo.annoScolastico,
          this.accordo.id,
          this.setupFormRequest()
        ).then((data) => {
          if (data) {
            this.dialog = false;
            this.$emit("refresh");
            /*return this.$router.push(
              `/scrivania/${this.capofila}/scuole-accordi`
            );*/
          }
        });
      }
    },
    setupFormRequest() {
      let accordoDaModificare = this.accordo;
      let idIstituto = this.istitutoAccordo;

      delete accordoDaModificare.istituto;
      delete accordoDaModificare.fileAccordo;
      delete accordoDaModificare.programmaOrientamento;

      accordoDaModificare.dataFirmaAccordo = this.formPDF.dataFirmaAccordo;
      const formBody = new FormData();
      formBody.append("scuola_accordo", JSON.stringify(accordoDaModificare));
      formBody.append("pdf_accordo", this.formPDF.pdfAccordo);
      formBody.append("istituto", idIstituto);
      return formBody;
    },
  },
};
</script>
