import Repository from "../Repository";

export default {
  async getAllProgrammiByIdStrutture(idStrutture) {
    const url = `api/istituto/${idStrutture}/formazione-professori/programmi`;
    return (await Repository.get(url)).data;
  },

  async saveFormazione(idStrutture, formazione) {
    const url = `api/istituto/${idStrutture}/formazione-professori/salva`;
    return await Repository.post(url, formazione);
  },
};
