<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Formazione professori/professoresse scuole superiori</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>
          Sezione in cui il rappresentante legale può inserire il numero di
          professori/professoresse di scuole superiori coinvolti in attività di
          formazione relativa all'orientamento e descrivere l'attività formativa
          svolta
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <small>I campi contrassegnati con asterisco sono obbligatori</small>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-expansion-panels focusable hover>
          <v-expansion-panel
            v-for="(programma, index) in programmiFormazione"
            :key="index"
          >
            <v-expansion-panel-header
              :disabled="programma.formazione.anno === 2024"
            >
              <v-row>
                <v-col class="align-start" cols="12"
                  ><v-card-title>
                    <h3
                      :class="{
                        'text--disabled': programma.formazione.anno === 2024,
                      }"
                    >
                      Formazione professori/professoresse scuole superiori anno
                      {{ programma.formazione.anno }}/{{
                        programma.formazione.anno + 1
                      }}
                    </h3>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-if="
                            !programma.formazione.numeroProfessori ||
                            !programma.formazione.dettaglioFormazione
                          "
                          color="red"
                          class="ml-2"
                          v-on="on"
                          v-bind="attrs"
                        >
                          mdi-alert-circle-outline
                        </v-icon>
                      </template>
                      <span>
                        Manca il numero di professori o i dettagli della
                        formazione.
                      </span>
                    </v-tooltip>
                  </v-card-title>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="pt-6">
                <v-col cols="4">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-on="on"
                        v-model="programma.formazione.numeroProfessori"
                        append-icon="mdi-information-outline"
                        hide-spin-buttons
                        type="number"
                        label="Numero professori/professoresse formati *"
                      >
                      </v-text-field>
                    </template>
                    <span
                      >Inserire numero di professori/professoresse delle scuole
                      superiori coinvolti in attività di orientamento nell'anno
                      scolastico di riferimento. Potranno essere richiesti in
                      seguito dettagli anagrafici dei professori/professoresse
                    </span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row class="pt-6">
                <v-col>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-textarea
                        hint="Massimo 1000 caratteri"
                        label="Dettaglio formazione erogata"
                        v-model="programma.formazione.dettaglioFormazione"
                        counter
                        v-on="on"
                        append-icon="mdi-information-outline"
                      >
                      </v-textarea>
                    </template>
                    <span
                      >Compilare il campo di testo descrivendo le attività
                      formative in cui sono stati coinvolti i
                      professori/professoresse delle scuole superiori</span
                    >
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex flex-row-reverse">
                  <v-btn
                    class="primary"
                    @click="openDialogSalva(programma)"
                    v-if="isRoleConsulenza || isRoleLegale"
                  >
                    Salva
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
            <v-dialog v-model="dialogSalva" max-width="500px">
              <v-card>
                <v-card-title class="headline"
                  >Conferma salvataggio</v-card-title
                >
                <v-card-text>
                  Sei sicuro di voler salvare i dati inseriti?
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="primary"
                    @click="confermaSalvataggio"
                    v-if="isRoleConsulenza || isRoleLegale"
                  >
                    Salva
                  </v-btn>
                  <v-btn color="red darken-1" text @click="dialogSalva = false">
                    Annulla
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      shaped
      :color="snackbarColor"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import FormazioneProfessoriRepository from "@/api/istituto/FormazioneProfessoriRepository";

export default {
  name: "FormazioneProfessoriView",
  components: {},
  computed: {
    ...mapState(["scrivaniaViewMode", "idStrutture", "anno"]),
    ...mapGetters(["isRoleConsulenza", "isRoleLegale"]),
  },
  data: () => ({
    dialogSalva: false,
    programmaSelezionato: null,
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    programmiFormazione: [],
    studentiCensitiAltro: null,
    loadingCensiti: true,
    loadingImmatricolati: true,
    formazione: {
      id: 0,
      anno: 0,
      idProgramma: 0,
      numeroProfessori: 0,
      dettaglioFormazione: null,
    },
  }),
  watch: {
    idStrutture() {
      this.initialize();
    },
    anno() {
      this.initialize();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.idStrutture != null) {
        this.getAllProgrammi();
      }
    },
    async getAllProgrammi() {
      let programmi =
        await FormazioneProfessoriRepository.getAllProgrammiByIdStrutture(
          this.idStrutture
        );
      this.programmiFormazione = programmi.map((element) => ({
        formazione: {
          id: 0,
          anno: element.anno,
          idProgramma: element.id,
          numeroProfessori: element.formazioneProfessori.numeroProfessori ?? 0,
          dettaglioFormazione:
            element.formazioneProfessori.dettaglioFormazione || "",
        },
      }));
    },
    openDialogSalva(programma) {
      this.programmaSelezionato = programma;
      this.dialogSalva = true;
    },
    async confermaSalvataggio() {
      this.dialogSalva = false;
      await this.salvaFormazione(this.idStrutture, this.programmaSelezionato);
    },
    async salvaFormazione(idStrutture, programma) {
      if (programma.formazione.numeroProfessori <= 0) {
        this.snackbarText = "Inserisci il numero di professori formati.";
        this.snackbarColor = "red";
        this.snackbar = true;
        return;
      }
      if (!programma.formazione.dettaglioFormazione) {
        this.snackbarText = "Inserisci il dettaglio formazione.";
        this.snackbarColor = "red";
        this.snackbar = true;
        return;
      }

      try {
        const response = await FormazioneProfessoriRepository.saveFormazione(
          idStrutture,
          programma.formazione
        );

        if (response.status === 200) {
          this.snackbarText = "Dati salvati con successo!";
          this.snackbarColor = "green";
        } else {
          this.snackbarText = "Errore nel salvataggio dei dati.";
          this.snackbarColor = "red";
        }
        this.snackbar = true;
      } catch (error) {
        console.error("Errore nel salvataggio:", error);
        this.snackbarText = "Errore nel salvataggio dei dati.";
        this.snackbarColor = "red";
        this.snackbar = true;
      }
    },
  },
};
</script>

<style scoped></style>
