<template>
  <v-row justify="center">
    <v-dialog :value="dialog" persistent max-width="700">
      <v-card>
        <v-toolbar :color="colorToolbar" dark class="text-h6">{{
          title
        }}</v-toolbar>
        <v-card-text>
          <div class="pa-4" v-html="textConferma"></div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="close"> Annulla </v-btn>
          <v-btn color="green darken-1" text @click="handleOk"> Ok </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "DialogConferma",
  props: {
    showDialog: {
      type: Boolean,
      required: true,
      description:
        "In base al valore, verifica se il dialog debba essere aperto immediatamente",
    },
    title: {
      type: String,
      required: false,
      default: "Attenzione!",
    },
    textConferma: {
      type: String,
      required: false,
      default: "Desideri confermare l'operazione selezionata?",
    },
    textSuccess: {
      type: String,
      required: false,
      default: "Operazione conclusa con successo!",
    },
    textError: {
      type: String,
      required: false,
      default: "Errore durante l'operazione. Contattare il supporto.",
    },
    response: {
      type: Object,
      required: false,
      default: null,
    },
    colorToolbar: {
      type: String,
      required: false,
      default: "primary",
    },
  },
  watch: {
    response: {
      deep: true,
      handler: function () {
        //se cambia la response devo gestire la risposta
      },
    },
  },
  created() {
    this.initialize();
  },
  computed: {},
  data() {
    return {
      dialog: false,
    };
  },

  methods: {
    initialize() {
      if (this.showDialog) this.dialog = true;
    },
    handleOk(event) {
      // Prevent modal from closing
      event.preventDefault();
      this.$emit("callback");
      this.$emit("close-dialog");
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped></style>
