<template>
  <v-dialog persistent size="xl" v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-on="on"
        v-bind="attrs"
        color="primary"
        outlined
        class="ma-3"
        @click="dialog = true"
      >
        Dati di Monitoraggio
      </v-btn>
    </template>
    <v-card elevation="4" v-if="loadingComponent">
      <v-card-title> Riepilogo monitoraggi </v-card-title>
      <v-card-text>
        <v-data-table :headers="headers" :items="monitoraggi" item-key="id">
          <!--eslint-disable -->
            <template v-slot:item.statoMonitoraggio="{ item }">
               <span v-if="item.dataConfermaMonitoraggio">
                 <v-chip color="success">Confermato</v-chip>
               </span>
              <span v-else>
                <v-chip color="red accent-2"> Non Confermato</v-chip>
              </span>
            </template>
          <template v-slot:item.dataConfermaMonitoraggio="{ item }">
            <span v-html="getDataConfermaMonitoraggio(item)"></span>
          </template>
          <template v-slot:item.periodo="{ item }">
            <span v-html="getPeriodoMonitoraggio(item)"> </span>
          </template>
          <!-- eslint-enable -->
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="3">
            <v-btn type="warning" @click="dialog = false">
              Chiudi Finestra</v-btn
            >
          </v-col>
          <v-col cols="3">
            <v-btn
              type="primary"
              @click="dialogConferma = true"
              :disabled="readOnly"
            >
              Conferma Ultimo monitoraggio
            </v-btn>
          </v-col>
        </v-row>
        <DialogConferma
          @callback="onSubmitMonitoraggio"
          :dialog.sync="dialogConferma"
          :title="'Conferma dati a sistema per il monitoraggio'"
          :text-conferma="'Stai per confermare che i dati inseriti nelle singole sezioni del sistema rispecchiano lo stato di avanzamento del progetto'"
          @close-dialog="dialogConferma = false"
        >
        </DialogConferma>
        <v-snackbar
          :color="snackbar.color"
          v-model="snackbar.value"
          height="100"
          width="500"
          multi-line
          right
        >
          {{ snackbar.text }}
        </v-snackbar>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import DialogConferma from "@/components/DialogConferma.vue";
import MonitoraggiRepository from "@/api/istituto/MonitoraggiRepository";

export default {
  name: "ModalElencoMonitoraggi",
  components: { DialogConferma },
  props: {
    readOnly: {
      type: Boolean,
      required: true,
    },
    monitoraggi: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    loadingComponent: false,
    dialog: false,
    headers: [
      {
        text: "CUP",
        value: "programma.cup",
      },
      {
        text: "Periodo",
        value: "periodo",
      },
      {
        text: "STATO",
        value: "statoMonitoraggio",
      },
      {
        text: "DATA CONFERMA",
        value: "dataConfermaMonitoraggio",
      },
    ],
    snackbar: {
      text: null,
      color: "green",
      value: false,
    },
    dialogConferma: false,
  }),
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.loadingComponent = this.monitoraggi.length > 0;
    },
    getDataConfermaMonitoraggio(item) {
      return item.dataConfermaMonitoraggio
        ? new Date(item.dataConfermaMonitoraggio).toLocaleString("it-IT")
        : "Non confermato";
    },
    getPeriodoMonitoraggio(item) {
      const dataRiferimento = new Date(item.dataRiferimentoMonitoraggio);
      return (
        dataRiferimento.getUTCMonth() + 1 + "/" + dataRiferimento.getFullYear()
      );
    },
    onSubmitMonitoraggio() {
      /*return (this.snackbar = {
        text: "Il monitoraggio può essere confermato solo dal Legale Rappresentante",
        color: "red accent-2",
        value: true,
      });*/
      let idStrutture = this.monitoraggi[0].idStrutture;
      let anno = this.monitoraggi[0].anno;
      let capofila = this.monitoraggi[0].capofila ? "capofila" : "istituzione";
      MonitoraggiRepository.confermaMonitoraggioMensile(
        idStrutture,
        anno,
        capofila
      )
        .then(() => {
          this.$emit("afterConfermaMonitoraggi");
          this.snackbar = {
            text: "Monitoraggio avvenuto con successo. Tutti i dati fin qui inseriti sono stati storicizzati",
            color: "green",
            value: true,
          };
        })
        .catch(() => {
          this.snackbar = {
            text: "Il monitoraggio non è andato a buon fine. Si prega di contattare il supporto.",
            color: "red accent-2",
            value: true,
          };
        })
        .finally(() => {
          this.dialogConferma = false;
          this.dialog = false;
        });
    },
  },
};
</script>
